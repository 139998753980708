import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import ServicePlan from './components/ServicePlan';
import Tab1 from './assets/溫情加值服務.png';
import { Form, Input, DatePicker, Radio, Select, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import callApi from './utils/callApi';
import Notice from './assets/溫馨提醒.jpg';
import moment from 'moment';

interface FormValues {
  name: string;                 // 親友姓名
  birthDate: moment.Moment;     // 親友出生日期
  gender: 'male' | 'female';    // 親友性別
  phone: string;               // 家屬聯絡電話
  funeralDate: moment.Moment;   // 出殯日期
  guarantor: string;           // 禮儀業者
  publicCeremony: string;      // 公祭台詞
  familyCeremony: string;      // 家祭台詞
  farewell: string;            // 遠行台詞
  action: 'sit' | 'stand' | 'turn' | 'bow' | 'worship' | 'other';  // 動作呈現
}

// 首先定義新的介面
interface Information {
  basic: {
    name: string;
    birthday: string;
    gender: string;
    phone: string;
    date: string;
    funeral: string;
  };
  ceremony: {
    public_sacrifice: string;
    family_sacrifice: string;
    travel: string;
    action: string;
  };
  upload: {
    image: string;
    voice: string;
  };
}

interface SubmitData {
  name: string;
  description: string;
  information: Information;
}

const Combo: React.FC = () => {
  
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [imageFiles, setImageFiles] = useState<any[]>([]);
  const [audioFiles, setAudioFiles] = useState<any[]>([]);

  // breadcrumb navigation
  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4">
      <div className="flex items-center text-sm">
        <button className='text-green-600' onClick={() => navigate('/')}>
          <HomeOutlined className="mr-1" />
        </button>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">服務方案</span>
      </div>
    </div>
  );


  const onFinish = async (values: FormValues) => {
    try {
      // 構建基本資料
      const submitData: SubmitData = {
        name: values.name,
        description: values.guarantor,
        information: {
          basic: {
            name: values.name,
            birthday: values.birthDate.format('YYYY-MM-DD'),
            gender: values.gender,
            phone: values.phone,
            date: values.funeralDate.format('YYYY-MM-DD'),
            funeral: values.guarantor
          },
          ceremony: {
            public_sacrifice: values.publicCeremony,
            family_sacrifice: values.familyCeremony,
            travel: values.farewell,
            action: values.action
          },
          upload: {
            image: "",
            voice: ""
          }
        }
      };

      const group_id = "6fbba4f2-d965-452a-8ec4-75e780317b25";
      
      // 先創建項目
      const response = await callApi.post(`/group/${group_id}`, submitData);
      const item_id = response.data.id;

      // 上傳圖片
      if (imageFiles.length > 0) {
        for (const file of imageFiles) {
          const formData = new FormData();
          formData.append('file', file.originFileObj);
          
          try {
            await callApi({
              method: 'post',
              url: `/item/${item_id}`,
              data: formData,
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
          } catch (error) {
            console.error('圖片上傳失敗：', error);
            message.error(`圖片 ${file.name} 上傳失敗`);
          }
        }
      }

      // 上傳音檔
      if (audioFiles.length > 0) {
        for (const file of audioFiles) {
          const formData = new FormData();
          formData.append('file', file.originFileObj);
          
          try {
            await callApi({
              method: 'post',
              url: `/item/${item_id}`,
              data: formData,
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
          } catch (error) {
            console.error('音檔上傳失敗：', error);
            message.error(`音檔 ${file.name} 上傳失敗`);
          }
        }
      }

      message.success('表單提交成功！');
      form.resetFields();
      setImageFiles([]);
      setAudioFiles([]);

    } catch (error) {
      message.error('提交失敗，請稍後重試');
      console.error('提交失敗：', error);
    }
  };

  return (
    <>
      <div className='flex flex-col gap-4 p-4'>
        <BreadcrumbNav />
        <ServicePlan />
        <img src={Tab1} alt="溫情加值" className="w-full" />
      </div>
    <div className="flex flex-col gap-4  mx-auto bg-[#F6F4EF] p-4 mb-4 pt-[30px] relative">
      
      <Form 
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        {/* 基本資訊 */}
        <div className="text-xl font-semibold tracking-[0.2em] decoration-from-font no-decoration-skip-ink font-inter leading-5 mb-6 text-center">
          形象建立資訊填寫
        </div>
        <div className="flex flex-col gap-4 bg-white p-4 rounded-lg mb-4">
          <h2 className='text-lg font-bold'>基本資訊</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <Form.Item label="親友姓名" name="name">
              <Input placeholder="請輸入服務名稱" />
            </Form.Item>

            <Form.Item label="親友出生日期" name="birthDate">
              <DatePicker placeholder="請選擇日期" style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="親友性別" name="gender">
              <Radio.Group>
                <Radio value="male">男</Radio>
                <Radio value="female">女</Radio>
              </Radio.Group>
            </Form.Item>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <Form.Item label="家屬聯絡電話" name="phone">
              <Input placeholder="請輸入電話號碼" />
            </Form.Item>

            <Form.Item label="出殯日期" name="funeralDate">
              <DatePicker placeholder="請選擇日期" style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="禮儀業者" name="guarantor" className='col-span-3 w-1/3'>
              <Select placeholder="恆天人本">
                <Select.Option value="恆天人本">恆天人本</Select.Option>
                <Select.Option value="十方福報">十方福報</Select.Option>
                <Select.Option value="和豐禮儀">和豐禮儀</Select.Option>
                <Select.Option value="御信生命服務">御信生命服務</Select.Option>
                <Select.Option value="心設計心諮商所">心設計心諮商所</Select.Option>
                <Select.Option value="恩國生命禮儀公司">恩國生命禮儀公司</Select.Option>
                <Select.Option value="恩慧生命">恩慧生命</Select.Option>
                <Select.Option value="憶">憶</Select.Option>
                <Select.Option value="成洲禮儀">成洲禮儀</Select.Option>
                <Select.Option value="承邑生命禮儀">承邑生命禮儀</Select.Option>
                <Select.Option value="政碩生命禮儀公司">政碩生命禮儀公司</Select.Option>
                <Select.Option value="新月會館">新月會館</Select.Option>
                <Select.Option value="立陽">立陽</Select.Option>
                <Select.Option value="萬昌生命禮儀">萬昌生命禮儀</Select.Option>
                <Select.Option value="蓮陞禮儀">蓮陞禮儀</Select.Option>
                <Select.Option value="金運山">金運山</Select.Option>
                <Select.Option value="長江會所">長江會所</Select.Option>
                <Select.Option value="芯銨禮儀有限公司">芯銨禮儀有限公司</Select.Option>
                <Select.Option value="風采禮品社">風采禮品社</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        {/* 祭禮 */}
        <div className="flex flex-col gap-4 bg-white p-4 rounded-lg mb-4">
          <h2 className='text-lg font-bold'>祭禮</h2>
          
          <div className="flex flex-col gap-6">
            {/* 公祭 */}
            <div className="flex flex-col gap-2">
              <h3>公祭</h3>
              <div className="text-gray-500 text-sm">台詞</div>
              <Form.Item name="publicCeremony">
                <Input.TextArea 
                  rows={4} 
                  placeholder="請輸入台詞"
                />
              </Form.Item>
            </div>

            {/* 家祭 */}
            <div className="flex flex-col gap-2">
              <h3>家祭</h3>
              <div className="text-gray-500 text-sm">台詞</div>
              <Form.Item name="familyCeremony">
                <Input.TextArea 
                  rows={4} 
                  placeholder="請輸入台詞"
                />
              </Form.Item>
            </div>

            {/* 遠行 */}
            <div className="flex flex-col gap-2">
              <h3>遠行</h3>
              <div className="text-gray-500 text-sm">台詞</div>
              <Form.Item name="farewell">
                <Input.TextArea 
                  rows={4} 
                  placeholder="請輸入台詞"
                />
              </Form.Item>
            </div>

            {/* 動作呈現 */}
            <div className="flex flex-col gap-2">
              <h3>動作呈現</h3>
              <Form.Item name="action">
                <Radio.Group>
                  <Radio value="sit">坐下</Radio>
                  <Radio value="stand">站起</Radio>
                  <Radio value="turn">轉身</Radio>
                  <Radio value="bow">揖手</Radio>
                  <Radio value="worship">敬禮</Radio>
                  <Radio value="other">其他（請填於備註欄）</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        </div>

        {/* 檔案上傳 */}
        <div className="flex flex-col gap-4 bg-white p-4 rounded-lg mb-4">
          <h2 className='text-lg font-bold'>檔案上傳</h2>

          {/* 親友圖片上傳 */}
          <div className="flex flex-col gap-2">
            <div className="space-y-2">
              <div className='block text-lg font-bold'>親友圖片</div>
              <div className="text-red-500 text-sm">※ 提醒您 圖片解析度需達1080P以上、需為單獨照片、需為正面照片</div>
            </div>
            
            <div className="flex gap-4">
              <Upload
                listType="picture-card"
                maxCount={3}
                multiple
                beforeUpload={() => false}
                onChange={({ fileList }) => setImageFiles(fileList)}
              >
                <div className="flex flex-col items-center text-blue-600">
                  <UploadOutlined />
                  <div>上傳圖片</div>
                </div>
              </Upload>
            </div>
          </div>

          {/* 親友聲音上傳 */}
          <div className="flex flex-col gap-2">
            <div className="space-y-2">
              <div className='block text-lg font-bold'>親友聲音</div>
              <div className="text-red-500 text-sm">※ 提醒您 聲音檔案不可有太多雜訊、僅可以是1人在說話、音檔說話長度需超過30秒</div>
            </div>

            <div className="flex flex-col gap-2">
              {/* 上傳按鈕 */}
              <Upload 
                className="audio-upload"
                beforeUpload={() => false}
                onChange={({ fileList }) => setAudioFiles(fileList)}
              >
                <Button icon={<UploadOutlined />} className="border border-dashed text-blue-600" style={{ height: '40px' }}>
                  上傳音檔
                </Button>
              </Upload>
            </div>
          </div>
        </div>
        
        {/* 提交按鈕 */}
        <div className="flex justify-center mt-6">
          <Button 
            type="primary" 
            htmlType="submit" 
            className="bg-[#11386F] w-[282px] h-[42px] rounded-tl-[5px]"
          >
            提交
          </Button>
        </div>
      </Form>
      <img src={Notice} alt="溫馨提醒" className="w-full" />
    </div>
    </>
  );
};

export default Combo;
