import { ReactComponent as UploadIcon } from "../assets/upload-icon.svg";

export const UploadButton = ({ text }: { text: string }) => (
  <div className="border border-dashed border-[#D9D9D9] rounded-[4px] p-2 w-full">
    <div className="flex items-center justify-between gap-2 text-[#1677FF] cursor-pointer">
      <span className="!text-[15px]">{text}</span>
      <UploadIcon />
    </div>
  </div>
);
