import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const getToken = (): string | null => {
  return localStorage.getItem('token');
}

const request: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  timeout: 20000,
});

request.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error: any) => {
  return Promise.reject(error);
});

request.interceptors.response.use((response: AxiosResponse) => {
  response.data.status = response.status;
  return response;
}, (error: any) => {
  if (error.response) {
    error.response.data.status = error.response.status;
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error);
});

export default request;
