import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Radio,
  Row,
  Typography,
  Upload,
  message,
  Spin,
  Form
} from "antd";
import { CalculatorOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { ReactComponent as CalendarIcon } from "./assets/calendar-icon.svg";
import { ReactComponent as SoundIcon } from "./assets/sound-icon.svg";
import { ReactComponent as VideoIcon } from "./assets/video-icon.svg";
import { useState, useEffect, useRef, useCallback } from "react";
import { UploadButton } from "./components/UploadButton";
import { AudioUpload } from "./components/AudioUpload";
import { PhotoUpload } from "./components/PhotoUpload";
import { ActionType } from "./components/ActionType";
import Pricelist from "./components/priceList/Pricelist";
import { gethealtechConfig } from "./config/config";
import { createDemandItem, uploadBlobToItem, updateDemandItem, searchDemandItem, searchDemandByName, deleteDemandItemBlob, updateDemandName, getItemById, getGroup } from "./services/api";
import { findBlobUrls, setBlobUrl2BlobID } from "./utils/blobUtils";
import dayjs from "dayjs";
import { Confirm } from "./components/Confirm";
import { useMqtt } from "./context/MqttContext";
import { RadioChangeEvent } from "antd/es/radio/interface";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

interface ExampleItem {
  id: number;
  title: string;
  content: string;
}

interface ExampleList {
  public: ExampleItem[];
  family: ExampleItem[];
  farewell: ExampleItem[];
}

const exampleList: ExampleList = {
  public: [
    {
      id: 1,
      title: "範例一",
      content: "親愛的家人和朋友們，此刻我用另一種方式陪伴著你們。回顧我的人生，雖然不能說完美無缺，但我為曾經擁有的一切感到無比幸福。\n\n謝謝我的家人，無論順境還是逆境，始終陪伴在我的身邊，給予我無盡的支持與愛；謝謝我的朋友，曾與我共享歡笑與淚水，豐富了我的人生。\n\n從今天起，我將化作微風、陽光，悄悄守護著你們。請別太難過，擦乾眼淚，帶著笑容繼續前行。生命的價值不在於長短，而是珍惜當下以及與所愛之人共度美好時光。\n\n感謝你們為我做的一切，也謝謝你們來送我最後一程。我已準備好踏上新的旅程，而你們的愛，將一直與我同在。\n\n珍重再見，願我們有緣再相逢。"
    },
    {
      id: 2,
      title: "範例二",
      content: "大家好，我是○○○，是○○○、○○○的超級老媽，更是○○○的妻子。\n\n各位親友，感謝你們今天特地來到這裡，送我最後一程。請不要為我流太多眼淚，因為我現在已經沒有病痛了。我會在天上保佑你們大家，未來你們一定要互相照顧並且平安、健康！\n\n我的這一生，有家人和你們的陪伴，已經非常幸福了。這47年來，我們一起經歷了很多酸甜苦辣，攜手建立了一個完整而幸福的家。謝謝你這麼多年來的包容與陪伴，讓我的生命充滿了愛與溫暖。\n\n現在我離開了，你們一定要好好照顧自己，別太累，也別讓自己過得太苦。日子還長，記得替我多看看這個世界，好好陪伴我們的孩子，幫我照顧好這個家，我永遠愛你們。"
    }
  ],
  family: [
    {
      id: 1,
      title: "範例一",
      content: "親愛的家人，我在另一個世界，向你們訴說我的思念與感謝。\n\n媽媽，從我來到這個世界的那一刻起，你就給了我無私的愛。你總是默默地為我付出，無論我做了什麼，你都包容我、支持我。媽媽請不要太過悲傷，未來的日子裡，請好好照顧自己，別再為我勞累了。\n\n爸爸，你的肩膀曾是我依靠的港灣，你的教誨讓我成長為一個有擔當的人。謝謝你總是以沉穩的力量守護著我們的家。爸爸，你是我的榜樣，我知道你會堅強面對一切。\n\n(兄弟姐妹)，你是我最牽掛的家人之一。從小到大，你都是我心中那個充滿笑容的小女孩。哥沒能陪你走更長的路，真的很抱歉，但我希望你知道，無論你在哪裡，我都會守護著你。\n\n(感情伴侶)，謝謝你走進我的生命，雖然我們的故事比預期短暫，但每一段回憶都珍貴無比。我希望你能記住我們在一起的美好，而不是沉浸在離別的痛苦中。我會在另一個地方為你祈福，希望你能遇見屬於你的幸福，繼續勇敢地愛與被愛。\n\n我親愛的家人與愛人，雖然我的身體已離開，但我的心從未遠去。我會以另一種形式，繼續守護你們。好好地活下去，好好地愛彼此。永遠愛你們的○○○"
    },
    {
      id: 2,
      title: "範例二",
      content: "老公啊，47年的感情早就刻在我們的生命裡了，我雖然不在，但這份牽掛永遠不會離開你。以後的日子，我會以另一種方式陪著你，你一定要堅強振作起來，記得身體一定要顧好，別讓我擔心。\n\n兒子啊，媽媽這輩子最驕傲的，就是看著你成長，變成一個懂事、有擔當的男人。雖然媽媽不在了，但你要記得，男人的肩膀是用來扛責任的，也別忘了，累了的時候，家永遠是你的避風港。\n\n女兒啊，從小媽媽看著你一步步長大，變成一個堅強又貼心的小寶貝，心裡真的特別欣慰。以後媽媽不在了，你要記得不管遇到什麼，家人永遠是你最好的依靠。\n\n媽媽希望你們都能開心地過好每一天，這就是我最大的心願，我永遠愛你們。"
    }
  ],
  farewell: [
    {
      id: 1,
      title: "範例一",
      content: "各位親愛的家人朋友們，今天我用另一種方式，來和你們說一聲感謝，也說一聲再見。我的一生因為有你們的陪伴，讓我的每一天都充滿了色彩。\n\n每一次和朋友的聚會，每一場和家人的爭吵後的和好，每一個你們給我的擁抱和鼓勵，都是我生命中最珍貴的回憶。\n\n這些點點滴滴，我都會好好地收藏起來，帶著它們繼續走向我的下一段旅程。\n\n我知道我的離開會讓你們感到難過，但我希望這段悲傷不會停留太久。請記得那些我們開心的時光，因為這些才是真正屬於我們的故事。\n\n(家人1)、(家人2)、(家人3)、(親密關係1)、(摯友)、(摯友2)，我永遠感激你們無條件的愛與支持，讓我成為現在的我。親愛的朋友們，謝謝你們讓我的人生充滿驚喜與溫暖。\n\n最後，請讓我們用一個笑容，告別今天的眼淚。我走得很快樂，因為有你們的愛，讓我的人生毫無遺憾。而你們才是我生命中最重要的寶藏。\n\n謝謝大家，珍重再見。"
    },
    {
      id: 2,
      title: "範例二",
      content: "感謝大家在我的人生中，帶來那麼多的歡笑和陪伴。你們的關心與支持，讓我的生活更加豐富。\n\n我雖然離開了，但你們的友誼和愛，我都帶走了，永存於心。\n\n請大家不要為我難過太久。我希望你們記得的，是我笑著的模樣，記得那些屬於我們的快樂時光。\n\n再未來的日子裡，每當你們回憶起時，你們臉上都是帶著笑容，因為我曾經是你們生命的一部分，而你們，也讓我的人生圓滿無憾。\n\n謝謝大家，珍重再見。"
    }
  ]
};

interface Blob {
  id: string;
  // 加入其他 blob 相關的屬性
}

interface UploadResponse {
  blobs: Blob[];
}

const DemandForm = (): JSX.Element => {
  const navigate = useNavigate();
  const GROUP_ID = gethealtechConfig().groupID;
  const { groupIDPriceList } = gethealtechConfig();
  const [form] = Form.useForm();

  // 使用 Form.useWatch 監聽台詞欄位
  const publicScript = Form.useWatch('publicScript', form);
  const familyScript = Form.useWatch('familyScript', form);
  const farewellScript = Form.useWatch('farewellScript', form);

  const [formType, setFormType] = useState<string>("首次填寫");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [version, setVersion] = useState<number>(0);
  const [jsonFileID, setJsonFileID] = useState<string>("");
  const existingItem = useRef<any>(null); // 紀錄現有item
  const existingJson = useRef<any>([]); // 紀錄現有json
  const [showCostCalculation, setShowCostCalculation] = useState<boolean>(false);
  const [showPricelistModal, setShowPricelistModal] = useState<boolean>(false);
  const [isPriceListLoading, setIsPriceListLoading] = useState<boolean>(false);

  // 價目表數據
  const [priceList, setPriceList] = useState({
    videoItems: [
      { name: '3min-video', spec: "3分鐘(含)內", price: 25000 },
      { name: '3-5min-video', spec: "3~5分鐘", price: 50000 },
      { name: '5-10min-video', spec: "5~10分鐘", price: 75000 },
      { name: '10min-video', spec: "10分鐘以上", price: 100000 }
    ],
    actionItems: [
      { name: 'sit', spec: "坐下", price: 1000 },
      { name: 'stand', spec: "站起", price: 1000 },
      { name: 'turn', spec: "轉身", price: 1000 },
      { name: 'bow', spec: "揖手", price: 1000 },
      { name: 'salute', spec: "敬禮", price: 1000 },
      { name: 'other', spec: "其他", price: 2000 }
    ]
  });

  // 加載價目表數據
  const fetchPriceList = async () => {
    setIsPriceListLoading(true);
    try {
      const groupResponse = await getGroup(groupIDPriceList);
      const itemID = groupResponse?.items?.[0]?.id;
      if (!itemID) return;
      const response = await getItemById(itemID);
      if (response && response.information) {
        const { videoItems, actionItems } = response.information;
        if (videoItems && actionItems) {
          setPriceList({ videoItems, actionItems });
        }
      }
    } catch (error) {
      console.error('獲取價目表數據失敗:', error);
      // 使用默認價格
    } finally {
      setIsPriceListLoading(false);
    }
  };
  useEffect(() => {
    fetchPriceList();
  }, []);

  // 音頻長度狀態
  const [audioDurations, setAudioDurations] = useState({
    publicAudio: 0,
    familyAudio: 0,
    farewellAudio: 0
  });

  const [cost, setCost] = useState({
    public: {
      price: 0,
      info: ''
    },
    family: {
      price: 0,
      info: ''
    },
    farewell: {
      price: 0,
      info: ''
    },
    action1: {
      price: 0,
      info: ''
    },
    action2: {
      price: 0,
      info: ''
    },
    total: 0,
  });
  const { publish } = useMqtt();

  // 更新音頻時長處理函數
  const handleAudioChange = useCallback((type: 'publicAudio' | 'familyAudio' | 'farewellAudio', audioInfo: { duration: number }) => {
    console.log('[DemandForm] handleAudioChange', type, audioInfo);
    setAudioDurations(prev => ({
      ...prev,
      [type]: audioInfo.duration
    }));
  }, []);

  // 監聽音頻時長變化，自動計算費用
  useEffect(() => {
    // 只有在至少有一個音頻時長大於0時才計算
    if (audioDurations.publicAudio > 0 || audioDurations.familyAudio > 0 || audioDurations.farewellAudio > 0) {
      handleCostCalculation();
    }
  }, [audioDurations]);

  const calculateCost = (audioSeconds: number): { price: number, time: string } => {
    // 如果沒有音頻，則返回零價格
    if (audioSeconds === 0) {
      return {
        price: 0,
        time: "尚未上傳音頻"
      };
    }

    const minutes = audioSeconds / 60;
    let videoItemIndex = 0;

    // 根據音頻長度劃分區段
    if (minutes <= 3) {
      videoItemIndex = 0; // 3分鐘(含)內
    } else if (minutes <= 5) {
      videoItemIndex = 1; // 3~5分鐘
    } else if (minutes <= 10) {
      videoItemIndex = 2; // 5~10分鐘
    } else {
      videoItemIndex = 3; // 10分鐘以上
    }

    // 從價目表獲取價格
    const videoPrice = priceList.videoItems[videoItemIndex]?.price || 25000;
    const timeText = priceList.videoItems[videoItemIndex]?.spec || "3分鐘(含)內";

    return {
      price: videoPrice,  // 改為只返回一個價格
      time: timeText
    };
  };

  const handleCostCalculation = () => {
    if (showCostCalculation === false) {
      setShowCostCalculation(true);
    }

    // 只使用音頻長度計算費用，不再使用台詞長度
    const familyCost = calculateCost(audioDurations.familyAudio);
    const publicCost = calculateCost(audioDurations.publicAudio);
    const farewellCost = calculateCost(audioDurations.farewellAudio);

    const action1Value = form.getFieldValue('action1') || '';
    const action2Value = form.getFieldValue('action2') || '';
    const actionTextMap: Record<string, string> = {
      "sit": "坐下",
      "stand": "站起",
      "turn": "轉身",
      "bow": "揖手",
      "salute": "敬禮",
      "other": "其他"
    };
    const action1Info = actionTextMap[action1Value] || '';
    const action2Info = actionTextMap[action2Value] || '';

    // 從價目表獲取動作價格
    const action1Item = priceList.actionItems.find(item => item.name === action1Value);
    const action2Item = priceList.actionItems.find(item => item.name === action2Value);

    const action1Cost = action1Value ? (action1Item?.price || 0) : 0;
    const action2Cost = action2Value ? (action2Item?.price || 0) : 0;

    const totalCost = familyCost.price + publicCost.price + farewellCost.price + action1Cost + action2Cost;

    setCost({
      public: {
        price: publicCost.price,
        info: publicCost.time
      },
      family: {
        price: familyCost.price,
        info: familyCost.time
      },
      farewell: {
        price: farewellCost.price,
        info: farewellCost.time
      },
      action1: {
        price: action1Cost,
        info: action1Info
      },
      action2: {
        price: action2Cost,
        info: action2Info
      },
      total: totalCost,
    })
  };

  const handleSubmit = () => {
    form.validateFields()
      .then(async (values) => {
        setIsLoading(true);
        const formData = {
          isEdited: false,
          isCreatedByBackend: false,
          basic: {
            name: values.name || "",
            birthDate: values.birthDate ? values.birthDate.format('YYYY-MM-DD') : null,
            gender: values.gender === "男" ? "male" : "female",
            familyName: values.familyName || "",
            familyPhone: values.familyPhone || "",
            familyEmail: values.familyEmail || "",
            funeralCompany: "恆天人本",
            funeralDate: values.funeralDate ? values.funeralDate.format('YYYY-MM-DD') : null,
            obituary: values.obituary?.[0]?.originFileObj || null
          },
          ceremony: {
            script: {
              public: values.publicScript || "",
              family: values.familyScript || "",
              farewell: values.farewellScript || ""
            },
            files: {
              dead: {
                audio: getFileList(values.deadAudio)
              },
              my: {
                public: { audio: getFileList(values.myPublicAudio), photo: getFileList(values.myPublicPhoto) },
                family: { audio: getFileList(values.myFamilyAudio), photo: getFileList(values.myFamilyPhoto) },
                farewell: { audio: getFileList(values.myFarewellAudio), photo: getFileList(values.myFarewellPhoto) },
                action1: { photo: getFileList(values.myAction1Photo) },
                action2: { photo: getFileList(values.myAction2Photo) }
              },
              created: {
                public: { audio: [], photo: [] },
                family: { audio: [], photo: [] },
                farewell: { audio: [], photo: [] },
                action1: { photo: [] },
                action2: { photo: [] }
              },
              selected: {
                public: {
                  audio: null,
                  video: null,
                  createdAudio: null
                },
                family: {
                  audio: null,
                  video: null,
                  createdAudio: null
                },
                farewell: {
                  audio: null,
                  video: null,
                  createdAudio: null
                }
              }
            },
            videoFormat: {
              public: values.publicVideoFormat,
              family: values.familyVideoFormat,
              farewell: values.farewellVideoFormat,
            },
            actionType1: {
              action: values.action1 || "",
              moreText: values.action1 === 'other'
                ? values.action1_moreText
                : getActionText(values.action1),
              hint: values.action1_hint || "",
              reverseHint: values.action1_reverse_hint || ""
            },
            actionType2: {
              action: values.action2 || "",
              moreText: values.action2 === 'other'
                ? values.action2_moreText
                : getActionText(values.action2),
              hint: values.action2_hint || "",
              reverseHint: values.action2_reverse_hint || ""
            },
            notes: values.notes || ""
          }
        }

        const response = await saveToBackend(formData);

        publish('eternal/demand', {
          action: 'fronted send data',
        });

        navigate('/');
        message.success('需求單儲存成功!');
      })
      .catch((error) => {
        console.log('error', error);
        form.scrollToField(error?.errorFields?.[0]?.name, {
          behavior: 'smooth',
          block: 'center'
        });
        message.error('請檢查表單填寫是否完整!');
      }).finally(() => {
        setIsLoading(false);
        setConfirmOpen(false);
      });
  };

  const getFileList = (sourceData: any[]) => {
    if (!sourceData?.length) return [];

    return sourceData.map((file: any) => {
      const { originFileObj } = file;

      // 如果有 originFileObj，建立新的 blob URL
      if (originFileObj) {
        const blobUrl = URL.createObjectURL(originFileObj);
        return {
          uid: file.uid,
          name: originFileObj.name,
          type: originFileObj.type,
          size: originFileObj.size,
          url: blobUrl,
          preview: originFileObj.type.startsWith('image/') ? blobUrl : undefined
        };
      }

      // 如果沒有 originFileObj 但有其他必要資訊，返回現有的檔案資訊
      if (file.uid && file.url) {
        return {
          uid: file.uid,
          name: file.name || '未命名檔案',
          type: file.type,
          size: file.size,
          url: file.url,
          preview: file.url
        };
      }

      return null;
    }).filter(Boolean); // 過濾掉 null 值
  }

  useEffect(() => {
    console.log('existingItem', existingItem.current);
    console.log('existingJson', existingJson.current);
  }, [existingItem.current, existingJson.current]);

  // [新增] 需求單到後端
  // 1. 準備資料
  // 2. 創建需求單
  // 3. 上傳檔案
  // 4. 更新需求單
  const saveToBackend = async (formData: any) => {
    // 設定變數
    let itemId = existingItem?.current?.id || "";
    let response = null;
    let sourceData = formData;
    let existingBlobs = existingItem?.current?.blobs || [];

    console.log('formData', formData);
    console.log('existingBlobs', existingBlobs);

    // 檢查並創建需求單
    const requestData = {
      group_id: GROUP_ID,
      data: {
        name: formData.basic.name,
        description: "demandForm",
        information: {}
      }
    };

    // 如果沒有 itemId，創建新的需求單
    if (!itemId) {
      response = await createDemandItem(requestData);
      itemId = response.id;
    }

    // 1. 準備資料
    const pendingUploadFiles = findBlobUrls(sourceData);
    console.log('pendingUploadFiles', pendingUploadFiles);

    // 2. 批次上傳檔案
    const newlyUploadedFiles = await uploadBlobFiles(itemId, existingBlobs, pendingUploadFiles);
    console.log('newlyUploadedFiles', newlyUploadedFiles);

    // 3. 更新項目資料
    const updatedSourceData = setBlobUrl2BlobID(sourceData, newlyUploadedFiles);
    console.log('updatedSourceData', updatedSourceData);

    // 4. 合併現有版本與新版本
    const updatedData = {
      version: existingJson.current
        ? [...existingJson.current, updatedSourceData]
        : [updatedSourceData]
    };

    // 5. 將 updatedSourceData 轉換為 JSON 檔案
    // 將物件轉換為 JSON 字串，然後創建 Blob
    const jsonString = JSON.stringify(updatedData);
    const jsonBlob = new Blob([jsonString], { type: 'application/json' });

    // 6. 上傳 JSON 檔案
    const uploadResponse = await uploadBlobToItem({
      item_id: itemId,
      file: jsonBlob,
    });

    // 7. 過濾出新的元素
    const newJsonBlob = uploadResponse.blobs.filter((uploadedBlob: any) => {
      // 先檢查 content_type
      if (uploadedBlob.content_type !== "application/json") {
        return false;
      }
      return true;
    }).sort((a: any, b: any) => {
      // 根據建立時間排序，最新的在前
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    })[0]; // 取第一個（最新的）
    console.log('newJsonBlob', newJsonBlob);

    // 8. 使用新元素更新需求單
    if (newJsonBlob) {
      await updateDemandItem({
        item_id: itemId,
        data: {
          jsonFile: newJsonBlob,
        }
      });

      formData?.basic?.name && await updateDemandName(itemId, formData.basic.name);
      jsonFileID && await deleteDemandItemBlob(itemId, jsonFileID);
    }

  };

  const uploadBlobFiles = async (
    itemId: string,
    existingBlobs: any[],
    pendingUploadFiles: any[]
  ): Promise<any[]> => {
    const processedBlobIds = new Set<string>();
    const newlyUploadedFiles: any[] = [];

    await Promise.all(pendingUploadFiles.map(async (file) => {
      // 檢查檔案是否已存在
      const isExisting = existingBlobs.some(blob =>
        blob.id === file.uid ||
        blob.url === file.url
      );

      if (isExisting) {
        console.log(`檔案已存在，跳過上傳: ${file.uid}`);
        return;
      }

      try {
        const response = await uploadBlobToItem({
          item_id: itemId,
          file: file
        }) as UploadResponse;

        const newBlob = response.blobs
          .filter(blob => !existingBlobs?.some(existingBlob => existingBlob.id === blob.id))
          .find(blob => !processedBlobIds.has(blob.id));

        if (!newBlob) {
          console.warn(`無法找到對應的新 blob 檔案: ${file.uid}`);
          return;
        }

        processedBlobIds.add(newBlob.id);
        newlyUploadedFiles.push({
          origin: {
            uid: file.uid,
            _path: file._path
          },
          newFile: newBlob
        });
      } catch (error) {
        console.error(`上傳檔案失敗: ${file.uid}`, error);
        throw new Error(`檔案上傳失敗: ${error instanceof Error ? error.message : '未知錯誤'}`);
      }
    }));

    return newlyUploadedFiles;
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.error(errorInfo.errorFields[0].errors[0]);
  };

  const handleExampleClick = (type: keyof ExampleList, exampleId: number) => {
    const example = exampleList[type].find((item: ExampleItem) => item.id === exampleId);
    console.log('範例:', example);
    if (!example) return;

    switch (type) {
      case 'public':
        form.setFieldsValue({ publicScript: example.content });
        break;
      case 'family':
        form.setFieldsValue({ familyScript: example.content });
        break;
      case 'farewell':
        form.setFieldsValue({ farewellScript: example.content });
        break;
    }
  };

  const getActionText = (action: string) => {
    switch (action) {
      case 'sit':
        return '坐下';
      case 'stand':
        return '站起';
      case 'turn':
        return '轉身';
      case 'bow':
        return '揖手';
      case 'salute':
        return '敬禮';
      case 'other':
        return '';
      default:
        return '';
    }
  };

  // 從資料中取得檔案
  const getFilesFromData = (formData: any, field: string) => {
    let files;

    switch (field) {
      case 'obituary':
        files = [formData.basic.obituary];
        break;
      case 'deadAudio':
        files = formData.ceremony.files.dead.audio;
        break;
      case 'myPublicAudio':
        files = formData.ceremony.files.my.public.audio;
        break;
      case 'myPublicPhoto':
        files = formData.ceremony.files.my.public.photo;
        break;
      case 'myFamilyAudio':
        files = formData.ceremony.files.my.family.audio;
        break;
      case 'myFamilyPhoto':
        files = formData.ceremony.files.my.family.photo;
        break;
      case 'myFarewellAudio':
        files = formData.ceremony.files.my.farewell.audio;
        break;
      case 'myFarewellPhoto':
        files = formData.ceremony.files.my.farewell.photo;
        break;
      case 'myAction1Photo':
        files = formData.ceremony.files.my.action1.photo;
        break;
      case 'myAction2Photo':
        files = formData.ceremony.files.my.action2.photo;
        break;
      default:
        files = formData[field];
        break;
    }

    if (!files) return null;

    // deadAudio 需要遍歷所有檔案，其他欄位只取第一個
    const targetFiles = field === 'deadAudio' ? files : [files[0]].filter(Boolean);

    return targetFiles.map((file: any) => ({
      uid: file.id,
      name: file.name,
      status: 'done',
      url: file.url,
      type: file.type,
      preview: file.url,
      thumbUrl: file.type?.startsWith('image/') ? file.url : undefined
    }));
  };

  const handleSearch = async (isSetFormData = false) => {
    try {
      const demandId = form.getFieldValue('demandId');
      const searchName = form.getFieldValue('searchName');

      if (!demandId && !searchName) {
        if (isSetFormData) {
          message.error('請輸入需求單編號或親友姓名');
        }
        existingItem.current = null;
        existingJson.current = [];
        return;
      }

      setIsLoading(true);

      let data;
      // 根據不同的搜尋條件使用不同的搜尋方法
      if (demandId) {
        // 使用需求單編號搜尋
        data = await searchDemandItem(demandId);
      } else {
        // 只有姓名時使用姓名搜尋
        data = await searchDemandByName(searchName);
      }

      console.log('data', data);

      if (!data) {
        message.error('查無資料');
        return null;
      }

      // 如果是用姓名搜尋可能會有多筆資料，需要讓使用者選擇
      if (Array.isArray(data)) {
        // TODO: 顯示選擇對話框讓使用者選擇要編輯哪一筆資料
        if (data.length === 0) {
          message.error('查無資料');
          return null;
        }
        // 暫時先取第一筆
        data = data[0];
      }

      const { information: { jsonFile }, blobs } = data;

      // 從 jsonFile.url 取得 version 資料
      const response = await fetch(jsonFile.url);
      const versionData = await response.json();
      const version = versionData.version || [];

      existingItem.current = data;
      existingJson.current = version;

      if (isSetFormData) {
        await setFormData(version);
      }

      message.success('查詢成功');
      return data;
    } catch (error: any) {
      if (error?.detail === 'Item not found') {
        message.error('查無資料');
      } else {
        console.error('查詢錯誤:', error);
        message.error('查詢發生錯誤，請稍後再試');
      }
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const setFormData = async (version: any) => {

    const latestVersionIndex = version.length - 1;

    // 將查詢結果填入表單
    const formData = version[latestVersionIndex]; // 假設資料結構中的最新版本

    form.setFieldsValue({
      // 需求單編號
      // demandId: data.id,
      // 搜尋姓名
      // searchName: data.name,

      // 基本資訊
      name: formData.basic.name,
      birthDate: formData.basic.birthDate ? dayjs(formData.basic.birthDate) : null,
      gender: formData.basic.gender === 'male' ? '男' : '女',
      familyName: formData.basic.familyName,
      familyPhone: formData.basic.familyPhone,
      familyEmail: formData.basic.familyEmail,
      funeralDate: formData.basic.funeralDate ? dayjs(formData.basic.funeralDate) : null,

      // 台詞內容
      publicScript: formData.ceremony.script.public,
      familyScript: formData.ceremony.script.family,
      farewellScript: formData.ceremony.script.farewell,

      // 影片格式
      publicVideoFormat: formData.ceremony.videoFormat.public,
      familyVideoFormat: formData.ceremony.videoFormat.family,
      farewellVideoFormat: formData.ceremony.videoFormat.farewell,

      // 動作設定
      action1: formData.ceremony.actionType1.action,
      action1_moreText: formData.ceremony.actionType1.moreText,
      action1_hint: formData.ceremony.actionType1.hint,
      action1_reverse_hint: formData.ceremony.actionType1.reverseHint,

      action2: formData.ceremony.actionType2.action,
      action2_moreText: formData.ceremony.actionType2.moreText,
      action2_hint: formData.ceremony.actionType2.hint,
      action2_reverse_hint: formData.ceremony.actionType2.reverseHint,

      // 備註
      notes: formData.ceremony.notes,
    });

    // 處理檔案資料
    const fileFields = [
      'obituary',
      'deadAudio',
      'myPublicAudio',
      'myPublicPhoto',
      'myFamilyAudio',
      'myFamilyPhoto',
      'myFarewellAudio',
      'myFarewellPhoto',
      'myAction1Photo',
      'myAction2Photo'
    ];

    fileFields.forEach(field => {
      const files = getFilesFromData(formData, field);
      if (files?.length) {
        form.setFieldValue(field, files);
      }
    });

  }

  const checkexistingItem = async () => {
    try {
      setIsLoading(true);
      let existingItemNow = await handleSearch(false);
      if (existingItemNow) {
        if (existingItemNow?.information?.jsonFile?.url) {
          const existingItemResponse = await fetch(existingItemNow.information.jsonFile.url);
          const data = await existingItemResponse.json();
          const versionCount = data?.version?.length || 0;

          if (versionCount >= 3) {
            message.error('此需求單已達修改上限（3次）!');
            setConfirmOpen(false);
            return;
          }
          setVersion(versionCount);
          setJsonFileID(existingItemNow?.information?.id);
        }
      }

      setConfirmOpen(true);
    } catch (error) {
      console.error('檢查舊資料時發生錯誤:', error);
      message.error('檢查資料時發生錯誤');
      setConfirmOpen(false);
    } finally {
      setIsLoading(false);
    }

  };

  return (
    <Spin spinning={isLoading}>
      <Confirm
        open={confirmOpen}
        title="請確認是否提交需求單?"
        content={`※ 提醒您, 每張需求單至多修改 3 次, 目前尚可使用修改次數：${3 - version} 次`}
        isLoading={isLoading}
        onConfirm={() => isLoading ? null : handleSubmit()}
        onCancel={() => {
          existingItem.current = null;
          existingJson.current = [];
          setVersion(0);
          setConfirmOpen(false);
        }}
      />
      <Form
        form={form}
        layout="vertical"
        onFinish={checkexistingItem}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        onValuesChange={(changedValues) => {
          // 當動作類型變化時，自動計算費用
          if ('action1' in changedValues || 'action2' in changedValues) {
            handleCostCalculation();
          }
        }}
      >
        <Title className="!text-[22px]" level={3} style={{ textAlign: "center" }}>
          形象建立資訊填寫
        </Title>

        <div>
          <div className="p-4" style={{ backgroundColor: "#ffffffcc" }}>
            <Title className="!text-[20px]" level={4}>
              需求單資訊
            </Title>
            <Row gutter={[16, 16]} className="flex flex-col gap-4">
              <Col>
                <Form.Item name="formType" initialValue="首次填寫">
                  <Radio.Group
                    onChange={(e: RadioChangeEvent) => {
                      setFormType(e.target.value);
                      if (e.target.value === "首次填寫") {
                        form.setFieldsValue({
                          demandId: undefined,
                          searchName: undefined
                        });
                      }
                    }}
                  >
                    <Radio className="!text-[15px] !bg-white [&_.ant-radio-checked_.ant-radio-inner]:!border-[#FFB81F] [&_.ant-radio-checked_.ant-radio-inner]:!bg-[#FFB81F]" value="首次填寫">
                      首次填寫
                    </Radio>
                    <Radio className="!text-[15px] !bg-white [&_.ant-radio-checked_.ant-radio-inner]:!border-[#FFB81F] [&_.ant-radio-checked_.ant-radio-inner]:!bg-[#FFB81F]" value="重複編輯">
                      重複編輯（擇一）
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24} className="flex flex-col">
                <div className="flex flex-col gap-2">
                  <Form.Item name="demandId">
                    <Input
                      placeholder={formType === "首次填寫" ? "系統將自動產生編號" : "請輸入需求單編號"}
                      disabled={formType === "首次填寫"}
                      className={formType === "首次填寫" ? "!bg-[#F5F5F5] !h-[40px] !rounded-[4px]" : "!h-[40px] !rounded-[4px]"}
                    />
                  </Form.Item>
                  {formType === "重複編輯" && (
                    <>
                      <Form.Item name="searchName">
                        <Input
                          placeholder="請輸入親友姓名"
                          className="!h-[40px] !rounded-[4px]"
                        />
                      </Form.Item>
                      <Button
                        className="!h-[40px] !rounded-[4px]"
                        onClick={() => handleSearch(true)}
                      >
                        查詢
                      </Button>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div>
          <div className="p-4" style={{ backgroundColor: "#ffffffcc" }}>
            <Title level={4} className="!text-[20px]">基本資訊</Title>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={12} md={8} lg={8}>
                <div className="flex flex-col gap-2">
                  <Text className="!text-[15px]">親友姓名</Text>
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: '請輸入親友姓名' }]}
                  >
                    <Input
                      placeholder="請輸入親友姓名"
                      className="!h-[40px] !rounded-[4px]"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <div className="flex flex-col gap-2">
                  <Text className="!text-[15px]">親友出生日期</Text>
                  <Form.Item
                    name="birthDate"
                  >
                    <DatePicker
                      style={{ width: "100%", height: "40px" }}
                      placeholder="請選擇日期"
                      suffixIcon={<CalendarIcon />}
                      className="!rounded-[4px]"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <div className="flex flex-col gap-2">
                  <Text className="!text-[15px]">親友性別</Text>
                  <Form.Item
                    name="gender"
                    initialValue="男"
                  >
                    <Radio.Group className="flex gap-8">
                      <Radio value="男" className="!text-[15px]">男</Radio>
                      <Radio value="女" className="!text-[15px]">女</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <div className="flex flex-col gap-2">
                  <Text className="!text-[15px]">家屬姓名（聯絡人）</Text>
                  <Form.Item
                    name="familyName"
                  // rules={[{ required: true, message: '請輸入家屬姓名' }]}
                  >
                    <Input
                      placeholder="請輸入家屬姓名"
                      className="!h-[40px] !rounded-[4px]"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <div className="flex flex-col gap-2">
                  <Text className="!text-[15px]">家屬聯絡電話</Text>
                  <Form.Item
                    name="familyPhone"
                  >
                    <Input
                      placeholder="請輸入電話號碼"
                      className="!h-[40px] !rounded-[4px]"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <div className="flex flex-col gap-2">
                  <Text className="!text-[15px]">家屬聯絡email</Text>
                  <Form.Item
                    name="familyEmail"
                    rules={[{ type: 'email', message: '請輸入有效的email格式' }]}
                  >
                    <Input
                      placeholder="請輸入email"
                      className="!h-[40px] !rounded-[4px]"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <div className="flex flex-col gap-2">
                  <Text className="!text-[15px]">禮儀業者</Text>
                  <Input
                    placeholder="恆天人本"
                    disabled
                    className="!h-[40px] !rounded-[4px] !bg-[#F5F5F5]"
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <div className="flex flex-col gap-2">
                  <Text className="!text-[15px]">出殯日期</Text>
                  <Form.Item
                    name="funeralDate"
                  >
                    <DatePicker
                      style={{ width: "100%", height: "40px" }}
                      placeholder="請選擇日期"
                      suffixIcon={<CalendarIcon />}
                      className="!rounded-[4px]"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <div className="flex flex-col gap-2">
                  <Text className="!text-[15px]">訃聞</Text>
                  <Form.Item
                    name="obituary"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e?.fileList || [];
                    }}
                  >
                    <Upload
                      name="obituary"
                      beforeUpload={() => false}
                      accept=".jpg,.jpeg,.png"
                      className={`
                        max-w-full
                        [&_.ant-upload-wrapper]:!w-full
                        [&_.ant-upload]:!w-full
                        [&_.ant-upload-list-item-image]:!object-contain
                      `}
                      maxCount={1}
                      listType="picture"
                    >
                      <UploadButton text="上傳訃聞照片" />
                    </Upload>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div style={{ backgroundColor: "#ffffffcc", padding: "20px" }}>
          <Title level={4} className="!text-[20px]">親友影片製作</Title>

          <Row gutter={[16, 16]} className="mt-4">
            <Col span={24}>
              <Text className="!text-[15px] font-medium flex items-center gap-2">
                <SoundIcon />
                聲音製作
              </Text>
            </Col>

            <Col span={24} className="flex flex-col gap-2">
              <Text className="!text-[15px]">親友聲音</Text>
              <Text type="danger" className="!text-[13px] !text-[#FF0000]">
                ※ 提醒您 聲音檔案不可有太多雜訊、僅可以是1人在說話、音檔說話長度需超過30秒
              </Text>

              <Form.Item
                name="deadAudio"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  return e?.fileList;
                }}
              >
                <Upload
                  name="deadAudio"
                  beforeUpload={() => false}
                  accept=".mp3,.wav,.m4a,.aac"
                  className={`
                    max-w-full
                    [&_.ant-upload-wrapper]:!w-full
                    [&_.ant-upload]:!w-full
                    [&_.ant-upload-list-item-image]:!object-contain
                  `}
                  listType="text"
                >
                  <UploadButton text="上傳音檔" />
                </Upload>
              </Form.Item>

            </Col>

          </Row>

          <Divider className="!my-6" />

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Text className="!text-[15px] font-medium flex items-center gap-2">
                <VideoIcon />
                影像製作
              </Text>
            </Col>

            <Col span={24} className="mt-2">
              <Text className="!text-[15px]">公祭</Text>
            </Col>

            <Col span={24} className="flex flex-col gap-2">
              <Text className="!text-[15px]">台詞</Text>
              <div className="flex gap-2 mb-2">
                <Button
                  className="!h-[32px] !rounded-[4px]"
                  onClick={() => handleExampleClick('public', 1)}
                >
                  範例一
                </Button>
                <Button
                  className="!h-[32px] !rounded-[4px]"
                  onClick={() => handleExampleClick('public', 2)}
                >
                  範例二
                </Button>
              </div>
              <Form.Item
                name="publicScript"
              >
                <Input.TextArea
                  placeholder="請輸入台詞"
                  rows={4}
                  showCount
                  maxLength={10000}
                  className="!rounded-[4px]"
                />
              </Form.Item>
            </Col>

            <Col span={24} className="flex flex-col gap-2">
              <AudioUpload
                name="myPublicAudio"
                form={form}
                onAudioChange={(audioInfo) => handleAudioChange('publicAudio', audioInfo)}
                required={!!publicScript}
              />
            </Col>

            <Col span={24} className="flex flex-col gap-2">
              <PhotoUpload name="myPublicPhoto" />
            </Col>

          </Row>

          <Divider className="!my-6" />
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Text className="!text-[15px]">家祭</Text>
            </Col>
            <Col span={24} className="flex flex-col gap-2">
              <Text className="!text-[15px]">台詞</Text>
              <div className="flex gap-2 mb-2">
                <Button
                  className="!h-[32px] !rounded-[4px]"
                  onClick={() => handleExampleClick('family', 1)}
                >
                  範例一
                </Button>
                <Button
                  className="!h-[32px] !rounded-[4px]"
                  onClick={() => handleExampleClick('family', 2)}
                >
                  範例二
                </Button>
              </div>
              <Form.Item
                name="familyScript"
              >
                <Input.TextArea
                  placeholder="請輸入台詞"
                  rows={4}
                  showCount
                  maxLength={10000}
                  className="!rounded-[4px]"
                />
              </Form.Item>
            </Col>
            <Col span={24} className="flex flex-col gap-2">
              <AudioUpload
                name="myFamilyAudio"
                form={form}
                onAudioChange={(audioInfo) => handleAudioChange('familyAudio', audioInfo)}
                required={!!familyScript}
              />
            </Col>
            <Col span={24} className="flex flex-col gap-2">
              <PhotoUpload
                name="myFamilyPhoto"
                refList={["myPublicPhoto"]}
                form={form}
              />
            </Col>
          </Row>

          <Divider className="!my-6" />
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Text className="!text-[15px]">遠行</Text>
            </Col>
            <Col span={24} className="flex flex-col gap-2">
              <Text className="!text-[15px]">台詞</Text>
              <div className="flex gap-2 mb-2">
                <Button
                  className="!h-[32px] !rounded-[4px]"
                  onClick={() => handleExampleClick('farewell', 1)}
                >
                  範例一
                </Button>
                <Button
                  className="!h-[32px] !rounded-[4px]"
                  onClick={() => handleExampleClick('farewell', 2)}
                >
                  範例二
                </Button>
              </div>
              <Form.Item
                name="farewellScript"
              >
                <Input.TextArea
                  placeholder="請輸入台詞"
                  rows={4}
                  showCount
                  maxLength={10000}
                  className="!rounded-[4px]"
                />
              </Form.Item>
            </Col>
            <Col span={24} className="flex flex-col gap-2">
              <AudioUpload
                name="myFarewellAudio"
                form={form}
                onAudioChange={(audioInfo) => handleAudioChange('farewellAudio', audioInfo)}
                required={!!farewellScript}
              />
            </Col>
            <Col span={24} className="flex flex-col gap-2">
              <PhotoUpload
                name="myFarewellPhoto"
                refList={["myFamilyPhoto", "myPublicPhoto"]}
                form={form}
              />
            </Col>
          </Row>
        </div>

        <div style={{ backgroundColor: "#ffffffcc", padding: "20px" }}>
          <Title level={4} className="!text-[20px]">親友動作製作</Title>
          <Row gutter={[16, 24]} className="mt-4">
            <Col span={24} className="flex flex-col gap-2">
              <PhotoUpload
                title="親友照片1"
                name="myAction1Photo"
                refList={["myFarewellPhoto", "myFamilyPhoto", "myPublicPhoto"]}
                form={form}
              />
            </Col>

            <Col span={24}>
              <ActionType name="action1" title="動作呈現1" />
            </Col>

            <Col span={24} className="flex flex-col gap-2">
              <PhotoUpload
                title="親友照片2"
                name="myAction2Photo"
                refList={["myAction1Photo", "myFarewellPhoto", "myFamilyPhoto", "myPublicPhoto"]}
                form={form}
              />
            </Col>

            <Col span={24}>
              <ActionType name="action2" title="動作呈現2" />
            </Col>

          </Row>
        </div>

        <div style={{ backgroundColor: "#ffffffcc", padding: "20px" }}>
          <Title level={4} className="!text-[20px]">親友形象問卷</Title>
          <Row gutter={[16, 16]} className="mt-4">
            <Col span={24} className="flex flex-col sm:flex-row justify-between items-center gap-4">
              <Text className="!text-[15px]">
                請前往表單側寫親友個性、喜好等問題，讓我們可以製作更加貼切的形象內容
              </Text>
              <Button
                type="primary"
                className="!h-[40px] !rounded-[4px] !bg-[#FFB81F] !text-[15px] hover:!bg-[#FFB81F]/80 w-full sm:w-auto"
                onClick={() => window.open('https://docs.google.com/forms/d/1xOUVhsXUl7P5_-crpuHvsPOsPQMSs_y_Oyp_RVxi8Xs/viewform?pli=1&pli=1&edit_requested=true', '_blank')}
              >
                前往問卷進行填寫
              </Button>
            </Col>
          </Row>
        </div>

        <div style={{ backgroundColor: "#ffffffcc", padding: "20px" }}>
          <Title level={4}>備註</Title>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="notes"
              >
                <Input.TextArea
                  placeholder="請輸入備註"
                  rows={4}
                  showCount
                  maxLength={10000}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        {/* 費用試算區塊 - 直接顯示在頁面上 */}
        {showCostCalculation && (
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <div style={{
                backgroundColor: "#F7F4EF",
                margin: "20px 0"
              }}>
                <div className="flex flex-col gap-4">
                  {/* 標題 */}
                  <div className="flex justify-center items-center mb-4">
                    <h2 style={{
                      fontWeight: 600,
                      fontSize: "22px",
                      textAlign: "center",
                      letterSpacing: "0.2em",
                      color: "#3E3D3D",
                      margin: 0
                    }}>
                      費用試算
                    </h2>
                  </div>

                  {/* 費用總計區塊 */}
                  <div
                    className="bg-white bg-opacity-80 p-5 flex flex-col gap-4 rounded-md cursor-pointer hover:bg-[#FFB81F] group"
                    onClick={() => {
                      fetchPriceList();
                      setShowPricelistModal(true);
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-1">
                        <span className="font-bold text-[16px] text-[#3E3D3D] flex items-center">費用總計</span>
                        <QuestionCircleOutlined className="text-[#FFB81F] text-lg flex items-center group-hover:text-[#3E3D3D]" />
                      </div>
                      <span className="font-bold text-[16px] text-[#3E3D3D] flex items-center">
                        {cost.total}
                      </span>
                    </div>
                  </div>

                  {/* 親友影音製作區塊 */}
                  <div className="bg-white bg-opacity-80 p-5 flex flex-col gap-4 rounded-md">
                    <div className="flex items-center">
                      <span className="font-medium text-[16px] text-[#11386F] flex items-center">親友影音製作</span>
                    </div>
                    {
                      cost.public.price > 0 && (
                        <VideoPrice title="公祭影片" cost={cost.public.price} time={cost.public.info} />
                      )
                    }
                    {
                      cost.family.price > 0 && (
                        <VideoPrice title="家祭影片" cost={cost.family.price} time={cost.family.info} />
                      )
                    }
                    {
                      cost.farewell.price > 0 && (
                        <VideoPrice title="遠行影片" cost={cost.farewell.price} time={cost.farewell.info} />
                      )
                    }
                  </div>

                  {/* 親友動作製作區塊 */}
                  <div className="bg-white bg-opacity-80 p-5 flex flex-col gap-4 rounded-md">
                    <div className="flex items-center">
                      <span className="font-medium text-[16px] text-[#11386F] flex items-center">親友動作製作</span>
                    </div>
                    {
                      cost.action1.info && (
                        <ActionPrice title={cost.action1.info} cost={cost.action1.price} />
                      )
                    }
                    {
                      cost.action2.info && (
                        <ActionPrice title={cost.action2.info} cost={cost.action2.price} />
                      )
                    }
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}

        {/* 費用試算按鈕 */}
        <Row justify="end" gutter={16} style={{ marginBottom: "20px" }}>
          <Col>
            <Button
              onClick={handleCostCalculation}
              style={{
                backgroundColor: "#F5F5F5",
                color: "#333",
                borderColor: "#D9D9D9",
                borderRadius: "4px",
                fontWeight: 500,
                height: "40px",
                boxShadow: "0 2px 0 rgba(0,0,0,0.02)"
              }}
              icon={<CalculatorOutlined />}
            >
              費用試算
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit" style={{
              height: "40px",
              borderRadius: "4px",
              fontWeight: 500,
              boxShadow: "0 2px 0 rgba(0,0,0,0.1)"
            }}>提交需求</Button>
          </Col>
        </Row>

        <Pricelist
          showPricelistModal={showPricelistModal}
          setShowPricelistModal={setShowPricelistModal}
          isEditMode={false}
          priceListData={priceList}
          isLoading={isPriceListLoading}
        />

      </Form>
    </Spin>
  );
};

const VideoPrice = ({ title, cost, time }: { title: string, cost: number, time: string }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <span className="text-[16px] text-[#3E3D3D] flex items-center">{title}</span>
        <span className="font-bold text-[16px] text-[#3E3D3D] flex items-center">
          {cost}
        </span>
      </div>
      <div className="flex items-center">
        <span className="text-[16px] text-[#8B8D97] flex items-center">{time}</span>
      </div>
    </div>
  )
}

const ActionPrice = ({ title, cost }: { title: string, cost: number }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <span className="text-[16px] text-[#3E3D3D] flex items-center">{title}</span>
        <span className="font-bold text-[16px] text-[#3E3D3D] flex items-center">{cost}</span>
      </div>
    </div>
  )
}

export default DemandForm;

