import { Typography, Form, Select, Row, Col, Input } from "antd";
import { ReactComponent as DropdownIcon } from "../assets/dropdown-icon.svg";
const { Text } = Typography;

interface ActionTypeProps {
  name: string;
  title: string;
}

export const ActionType = ({ name, title }: ActionTypeProps) => {
  const selectedValue = Form.useWatch(name);

  return (
    <Row gutter={[16, 24]}>
      <Col xs={24} sm={8}>
        <Text className="!text-[15px]">{title}</Text>
        <Form.Item
          name={name}
          className="mt-2"
        >
          <Select
            placeholder="請選擇"
            className="!h-[40px]"
            allowClear
          >
            <Select.Option value="sit" text="坐下">坐下</Select.Option>
            <Select.Option value="stand" text="站起">站起</Select.Option>
            <Select.Option value="turn" text="轉身">轉身</Select.Option>
            <Select.Option value="bow" text="揖手">揖手</Select.Option>
            <Select.Option value="salute" text="敬禮">敬禮</Select.Option>
            <Select.Option value="other" text="其他">其他</Select.Option>
          </Select>
        </Form.Item>
        {selectedValue === "other" && (
          <Form.Item
            name={`${name}_moreText`}
            rules={[{ required: true, message: "請輸入其他動作內容指示" }]}
            className="mt-2"
          >
            <Input.TextArea
              placeholder="請輸入其他動作內容指示"
              rows={1}
              className="!min-h-[60px]"
            />
          </Form.Item>
        )}
      </Col>
      {selectedValue === "other" && (
        <>
          <Col xs={24} sm={8}>
            <Text className="!text-[15px]">輸入提示詞</Text>
            <Form.Item
              name={`${name}_hint`}
            className="mt-2"
            >
              <Input
                placeholder="請輸入提示詞"
                className="!h-[40px]"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Text className="!text-[15px]">輸入反向提示詞</Text>
            <Form.Item
              name={`${name}_reverse_hint`}
              className="mt-2"
            >
              <Input
                placeholder="請輸入反向提示詞"
                className="!h-[40px]"
              />
            </Form.Item>
          </Col>
        </>
      )}
    </Row>
  );
};

