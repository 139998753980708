/**
 * 檔案處理相關工具函數
 */

interface BlobFile extends Blob {
  url?: string;
  uid?: string | number;
  preview?: string;
  _path?: string;
  [key: string]: any;
}

interface UploadedFile {
  origin: BlobFile;
  newFile: {
    url: string;
  };
}

/**
 * 尋找物件中所有的 Blob URL
 * @param {Object} data - 要搜尋的資料物件
 * @returns {Array} 包含 Blob URL 的檔案物件陣列
 */
export const findBlobUrls = (data: any): BlobFile[] => {
  const blobFiles: BlobFile[] = [];
  // 用於追蹤已處理過的路徑和對應的索引
  const processedPathIndices = new Map<string, number>();

  const findBlobs = (obj: any, path: string[] = []): void => {
    if (!obj || typeof obj !== 'object') return;

    // 檢查是否為檔案物件（具有 url 或 uid 屬性）
    if (obj.url || obj.uid) {
      // 合併路徑成字串以進行檢查
      const pathString = path.join('.');

      if (processedPathIndices.has(pathString)) {
        // 如果這個路徑已處理過，但當前對象有url而之前的沒有，則更新為當前對象
        const existingIndex = processedPathIndices.get(pathString)!;
        if (obj.url && !blobFiles[existingIndex].url) {
          blobFiles[existingIndex] = { ...obj, _path: pathString };
        }
      } else {
        // 未處理過的路徑，添加到結果並記錄索引
        const index = blobFiles.length;
        processedPathIndices.set(pathString, index);
        blobFiles.push({
          ...obj,
          _path: pathString
        });
      }
    }

    // 檢查是否為 File 或 Blob 物件
    if (obj instanceof File || obj instanceof Blob) {
      const url = URL.createObjectURL(obj);
      const pathString = path.join('.');

      if (processedPathIndices.has(pathString)) {
        // 如果這個路徑已處理過，而且我們剛生成了url，則更新url
        const existingIndex = processedPathIndices.get(pathString)!;
        blobFiles[existingIndex].url = url;
      } else {
        // 未處理過的路徑，添加到結果並記錄索引
        const index = blobFiles.length;
        processedPathIndices.set(pathString, index);
        blobFiles.push({
          ...obj,
          url,
          _path: pathString
        });
      }
    }

    // 遞迴處理所有屬性
    Object.entries(obj).forEach(([key, value]) => {
      if (value && typeof value === 'object') {
        findBlobs(value, [...path, key]);
      }
    });
  };

  findBlobs(data);
  return blobFiles;
};

/**
 * 更新資料結構中的 Blob URL
 * @param {Object} data - 原始資料物件
 * @param {Array} uploadedFiles - 已上傳的檔案資訊
 * @returns {Object} 更新後的資料物件
 */
export const setBlobUrl2BlobID = (data: any, uploadedFiles: UploadedFile[]): any => {
  const newData = JSON.parse(JSON.stringify(data));

  uploadedFiles?.forEach(uploadedFile => {
    if (uploadedFile.origin._path) {
      const pathParts = uploadedFile.origin._path.split('.');
      const parent = pathParts.slice(0, -1).reduce((obj, key) => obj[key], newData);
      const lastKey = pathParts[pathParts.length - 1];

      if (parent[lastKey]) {
        // 處理有 url 的情況
        if (parent[lastKey].url?.startsWith('blob:')) {
          parent[lastKey].url = uploadedFile.newFile.url;
        }
        // 處理有 preview 的情況
        if (parent[lastKey].preview?.startsWith('blob:')) {
          parent[lastKey].preview = uploadedFile.newFile.url;
        }
        // 處理只有 uid 的情況
        if (parent[lastKey].uid && !parent[lastKey].url) {
          parent[lastKey].url = uploadedFile.newFile.url;
        }
        // 移除 thumbUrl
        delete parent[lastKey].originFileObj;
      }
    }
  });

  return newData;
};

export const getValueFromEvent = (e: any, existFileList: any[] = []) => {
  console.log('e', e);
  if (Array.isArray(e)) {
    return e;
  }
  const fileList = e?.fileList?.map((file: any) => file.originFileObj);
  console.log('fileList', fileList);
  return fileList;
}
