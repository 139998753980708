import { useState, useEffect, useCallback, useRef } from 'react';
import mqtt from 'mqtt';

/**
 * MQTT 連線狀態類型
 * 'disconnected': 未連接
 * 'connected': 已連接
 * 'reconnecting': 重新連接中
 * 'error': 連接錯誤
 * 'offline': 離線
 */
type ConnectionStatus = 'disconnected' | 'connected' | 'reconnecting' | 'error' | 'offline';

/**
 * MQTT 消息負載類型
 * 用於表示一個 MQTT 消息的內容，可以是基本類型或複雜對象
 */
type MqttPayload = string | number | boolean | null | Record<string, any> | any[];

/**
 * 按主題索引的消息集合類型
 * 用於存儲不同主題下收到的最新消息
 */
type TopicMessages = Record<string, MqttPayload>;

/**
 * MQTT 消息類型
 * 可以是字符串或任何對象
 */
type MqttMessage = string | Record<string, any>;

/**
 * MQTT 訂閱回調函數類型
 * @param message - 收到的消息內容，使用 MqttPayload 類型
 */
type SubscriptionCallback = (message: MqttPayload) => void;

/**
 * 待處理訂閱請求接口
 * @property topic - 要訂閱的主題
 * @property callback - 可選的回調函數，處理收到的消息
 */
interface PendingSubscription {
  topic: string;
  callback?: SubscriptionCallback | null;
}

/**
 * MQTT Hook 返回值接口
 * @property connectionStatus - 當前連接狀態
 * @property messages - 按主題索引的消息記錄
 * @property subscribe - 訂閱主題的函數
 * @property unsubscribe - 取消訂閱主題的函數
 * @property publish - 發布消息到指定主題的函數
 * @property client - MQTT 客戶端實例
 * @property registerDemandCallback - 註冊 eternal/demand 主題的回調
 * @property unregisterDemandCallback - 取消註冊 eternal/demand 主題的回調
 * @property clearDemandCallbacks - 清除所有 eternal/demand 主題的回調
 */
interface MqttHookReturn {
  connectionStatus: ConnectionStatus;
  messages: TopicMessages;
  subscribe: (topic: string, callback?: SubscriptionCallback) => boolean;
  unsubscribe: (topic: string) => boolean;
  publish: (topic: string, message: MqttMessage) => boolean;
  client: mqtt.MqttClient | null;
  registerDemandCallback: (callback: SubscriptionCallback) => void;
  unregisterDemandCallback: (callback: SubscriptionCallback) => void;
  clearDemandCallbacks: () => void;
}

/**
 * MQTT 連接選項接口
 */
interface MqttConnectionOptions {
  clientId?: string;
  username?: string;
  password?: string;
  keepalive?: number;
  reconnectPeriod?: number;
  connectTimeout?: number;
  [key: string]: any;
}

/**
 * 自定義 MQTT hook，用於處理 MQTT 連接和消息訂閱
 * @param {string} brokerUrl - MQTT broker 的 URL
 * @param {MqttConnectionOptions} options - MQTT 連接選項
 * @returns {MqttHookReturn} MQTT 狀態和操作方法
 */
export const useMqtt = (
  brokerUrl = 'wss://api-broker.scfg.io:8083', 
  options: MqttConnectionOptions = {}
): MqttHookReturn => {
  // 狀態和引用
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('disconnected');
  const [messages, setMessages] = useState<TopicMessages>({});
  const clientRef = useRef<mqtt.MqttClient | null>(null);
  const subscriptionsRef = useRef<Record<string, SubscriptionCallback[]>>({});
  const pendingSubscriptionsRef = useRef<PendingSubscription[]>([]);
  const isProcessingSubscriptionsRef = useRef<boolean>(false);
  const demandCallbacksRef = useRef<SubscriptionCallback[]>([]);

  // 訂閱主題的通用函數
  const subscribeToTopic = useCallback((
    client: mqtt.MqttClient, 
    topic: string, 
    callback: SubscriptionCallback | null = null, 
    retryDelay = 2000
  ): boolean => {
    if (!client || !client.connected) {
      console.warn(`無法訂閱 ${topic}：客戶端未連接`);
      return false;
    }

    try {
      client.subscribe(topic, (err) => {
        if (err) {
          console.error(`訂閱 ${topic} 失敗:`, err);

          // 如果是連接問題，嘗試延遲重試
          if (err.message && (err.message.includes('disconnecting') || err.message.includes('disconnected'))) {
            console.log(`由於連接問題，將在 ${retryDelay}ms 後重試訂閱 ${topic}`);
            setTimeout(() => {
              if (client && client.connected) {
                subscribeToTopic(client, topic, callback, retryDelay * 1.5);
              }
            }, retryDelay);
          }
          return false;
        }

        console.log(`已成功訂閱 ${topic}`);

        // 保存訂閱回調
        if (!subscriptionsRef.current[topic]) {
          subscriptionsRef.current[topic] = [];
        }

        if (callback && typeof callback === 'function') {
          const callbackExists = subscriptionsRef.current[topic].some(
            existingCallback => existingCallback === callback
          );

          if (!callbackExists) {
            subscriptionsRef.current[topic].push(callback);
          }
        }

        return true;
      });

      return true;
    } catch (error) {
      console.error(`訂閱 ${topic} 時發生異常:`, error);
      return false;
    }
  }, []);

  // 處理待處理訂閱請求
  const processPendingSubscriptions = useCallback(() => {
    if (!clientRef.current || isProcessingSubscriptionsRef.current || pendingSubscriptionsRef.current.length === 0) {
      return;
    }

    // 確保客戶端確實已連接
    if (!clientRef.current.connected) {
      console.log('客戶端未連接，暫時不處理待處理的訂閱請求');
      return;
    }

    isProcessingSubscriptionsRef.current = true;
    console.log('開始處理待處理的訂閱請求:', pendingSubscriptionsRef.current.length);

    // 從隊列中取出第一個請求進行處理
    const { topic, callback } = pendingSubscriptionsRef.current[0];

    const success = subscribeToTopic(clientRef.current, topic, callback || null);

    // 從待處理隊列中移除已處理的請求
    pendingSubscriptionsRef.current.shift();

    // 釋放處理標記
    isProcessingSubscriptionsRef.current = false;

    // 如果隊列中還有請求，延遲後繼續處理
    if (pendingSubscriptionsRef.current.length > 0) {
      setTimeout(() => {
        processPendingSubscriptions();
      }, success ? 500 : 1000); // 成功時使用較短延遲，失敗時使用較長延遲
    }
  }, [subscribeToTopic]);

  // 創建 MQTT 連接
  useEffect(() => {
    // 連接到 MQTT broker
    const client = mqtt.connect(brokerUrl, {
      ...options,
      clientId: `teamsync-frontend-${Math.random().toString(16).substr(2, 8)}`,
      keepalive: 60,
      reconnectPeriod: 3000,
      connectTimeout: 10000
    });

    // 設置事件處理器
    client.on('connect', () => {
      console.log('MQTT 已連接');
      setConnectionStatus('connected');

      // 連接成功後，延遲一段時間再訂閱 eternal/demand 主題
      setTimeout(() => {
        if (client && client.connected) {
          const demandTopic = 'eternal/demand';
          subscribeToTopic(client, demandTopic);
        } else {
          console.warn('延遲後檢查到客戶端未連接，無法訂閱 eternal/demand');
        }
      }, 1500); // 延遲 1.5 秒確保連接穩定

      // 處理待處理的訂閱請求
      if (pendingSubscriptionsRef.current.length > 0) {
        console.log(`有 ${pendingSubscriptionsRef.current.length} 個待處理訂閱請求，將延遲處理`);
        setTimeout(() => {
          if (client.connected) {
            processPendingSubscriptions();
          } else {
            console.warn('延遲後客戶端已斷開連接，無法處理待處理訂閱');
          }
        }, 2000); // 延遲 2 秒再處理訂閱
      }
    });

    client.on('error', (err) => {
      console.error('MQTT 連接錯誤:', err);
      setConnectionStatus('error');
    });

    client.on('offline', () => {
      console.log('MQTT 離線');
      setConnectionStatus('offline');
    });

    client.on('reconnect', () => {
      console.log('MQTT 嘗試重新連接');
      setConnectionStatus('reconnecting');
    });

    client.on('message', (topic, message) => {
      const payload = message.toString();
      let parsedMessage: MqttPayload;

      try {
        parsedMessage = JSON.parse(payload);
      } catch (e) {
        parsedMessage = payload;
      }

      console.log(`從 ${topic} 接收到消息:`, parsedMessage);

      // 更新消息狀態
      setMessages(prev => ({
        ...prev,
        [topic]: parsedMessage
      }));

      // 處理 eternal/demand 主題的特殊回調
      if (topic === 'eternal/demand' && demandCallbacksRef.current.length > 0) {
        demandCallbacksRef.current.forEach(callback => {
          if (callback && typeof callback === 'function') {
            callback(parsedMessage);
          }
        });
      }

      // 處理一般主題回調
      if (subscriptionsRef.current[topic]) {
        subscriptionsRef.current[topic].forEach(callback => {
          if (callback && typeof callback === 'function') {
            callback(parsedMessage);
          }
        });
      }
    });

    // 保存客戶端引用
    clientRef.current = client;

    // 清理函數
    return () => {
      if (client) {
        // 取消所有訂閱
        Object.keys(subscriptionsRef.current).forEach(topic => {
          client.unsubscribe(topic);
        });

        // 斷開連接
        client.end();
        console.log('MQTT 連接已斷開');
      }
    };
  }, [brokerUrl, options, processPendingSubscriptions, subscribeToTopic]);

  // 訂閱主題
  const subscribe = useCallback((topic: string, callback?: SubscriptionCallback): boolean => {
    if (!clientRef.current || !clientRef.current.connected) {
      console.log(`MQTT 客戶端未連接，將在連接成功後自動訂閱: ${topic}`);

      // 檢查隊列中是否已存在相同主題的訂閱請求
      const existingSubscription = pendingSubscriptionsRef.current.find(item => item.topic === topic);

      if (existingSubscription) {
        console.log(`主題 ${topic} 已在待處理隊列中，不重複添加`);
        return true;
      }

      // 將訂閱請求添加到待處理隊列
      pendingSubscriptionsRef.current.push({ topic, callback });
      return true;
    }

    // 如果客戶端已連接，直接處理訂閱
    return subscribeToTopic(clientRef.current, topic, callback || null);
  }, [subscribeToTopic]);

  // 取消訂閱主題
  const unsubscribe = useCallback((topic: string): boolean => {
    // 從待處理訂閱隊列中移除該主題的訂閱
    if (pendingSubscriptionsRef.current.length > 0) {
      pendingSubscriptionsRef.current = pendingSubscriptionsRef.current.filter(
        item => item.topic !== topic
      );
      console.log(`已從待處理隊列中移除主題: ${topic}`);
    }

    if (!clientRef.current || !clientRef.current.connected) {
      console.warn('MQTT 客戶端未連接，無法取消訂閱');
      return true; // 返回 true 因為我們已經處理了待處理隊列
    }

    try {
      clientRef.current.unsubscribe(topic, (err) => {
        if (err) {
          console.error(`取消訂閱 ${topic} 失敗:`, err);
          return;
        }

        console.log(`已取消訂閱 ${topic}`);

        // 清除該主題的訂閱回調
        delete subscriptionsRef.current[topic];
      });

      return true;
    } catch (error) {
      console.error(`取消訂閱 ${topic} 時發生異常:`, error);
      return false;
    }
  }, []);

  // 發佈消息
  const publish = useCallback((topic: string, message: MqttMessage): boolean => {
    if (!clientRef.current || !clientRef.current.connected) {
      console.warn('MQTT 客戶端未連接，無法發佈消息');
      return false;
    }

    const payload = typeof message === 'object'
      ? JSON.stringify(message)
      : message;

    try {
      clientRef.current.publish(topic, payload, (err) => {
        if (err) {
          console.error(`發佈到 ${topic} 失敗:`, err);
          return;
        }

        console.log(`已發佈消息到 ${topic}:`, message);
      });

      return true;
    } catch (error) {
      console.error(`發佈消息到 ${topic} 發生異常:`, error);
      return false;
    }
  }, []);

  // demand 主題相關方法
  const registerDemandCallback = useCallback((callback: SubscriptionCallback): void => {
    if (callback && typeof callback === 'function') {
      const exists = demandCallbacksRef.current.some(cb => cb === callback);
      if (!exists) {
        demandCallbacksRef.current.push(callback);
        console.log('已註冊 demand 主題回調函數');
      }
    }
  }, []);

  const unregisterDemandCallback = useCallback((callback: SubscriptionCallback): void => {
    if (callback && typeof callback === 'function') {
      demandCallbacksRef.current = demandCallbacksRef.current.filter(cb => cb !== callback);
      console.log('已取消註冊 demand 主題回調函數');
    }
  }, []);

  const clearDemandCallbacks = useCallback((): void => {
    demandCallbacksRef.current = [];
    console.log('已清除所有 demand 主題回調函數');
  }, []);

  // 返回 hook API
  return {
    connectionStatus,
    messages,
    subscribe,
    unsubscribe,
    publish,
    client: clientRef.current,
    registerDemandCallback,
    unregisterDemandCallback,
    clearDemandCallbacks
  };
};

export default useMqtt; 