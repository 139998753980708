import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputNumber, message } from 'antd';
import './Pricelist.css';
import { updateDemandItem, createDemandItem } from '../../services/api';
import { gethealtechConfig } from '../../config/config';

interface PriceItem {
  name: string;
  spec: string;
  price: number;
}

interface PriceListFormValues {
  videoItems: { price: number }[];
  actionItems: { price: number }[];
}

interface PriceItemProps {
  specification: string;
  price: number;
  index: number;
  sectionName: 'videoItems' | 'actionItems';
  isEditMode: boolean;
}

interface SectionTitleProps {
  title: string;
  description: string;
}

interface SectionProps {
  title: string;
  description: string;
  items: PriceItem[];
  sectionName: 'videoItems' | 'actionItems';
  isEditMode: boolean;
}

interface PricelistProps {
  showPricelistModal: boolean;
  setShowPricelistModal: (show: boolean) => void;
  isEditMode?: boolean;
  priceListData?: {
    videoItems: PriceItem[];
    actionItems: PriceItem[];
  };
  isLoading?: boolean;
}

const Pricelist: React.FC<PricelistProps> = ({
  showPricelistModal,
  setShowPricelistModal,
  isEditMode = false,
  priceListData,
  isLoading = false
}) => {
  const [form] = Form.useForm<PriceListFormValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const { groupIDPriceList } = gethealtechConfig();

  // 設定初始價格資料
  const defaultVideoItems: PriceItem[] = [
    { name: '3min-video', spec: "3分鐘(含)內", price: 1000 },
    { name: '3-5min-video', spec: "3~5分鐘", price: 2000 },
    { name: '5-10min-video', spec: "5~10分鐘", price: 3000 },
    { name: '10min-video', spec: "10分鐘以上", price: 5000 }
  ];

  const defaultActionItems: PriceItem[] = [
    { name: 'sit', spec: "坐下", price: 1000 },
    { name: 'stand', spec: "站起", price: 1000 },
    { name: 'turn', spec: "轉身", price: 1000 },
    { name: 'bow', spec: "揖手", price: 1000 },
    { name: 'salute', spec: "敬禮", price: 1000 },
    { name: 'other', spec: "其他", price: 2000 }
  ];

  // 使用傳入的 priceListData 或默認值
  const [videoItems, setVideoItems] = useState<PriceItem[]>(
    priceListData?.videoItems || defaultVideoItems
  );
  const [actionItems, setActionItems] = useState<PriceItem[]>(
    priceListData?.actionItems || defaultActionItems
  );

  // 當 priceListData 改變時更新狀態
  useEffect(() => {
    if (priceListData) {
      if (priceListData.videoItems && priceListData.videoItems.length > 0) {
        setVideoItems(priceListData.videoItems);
      }
      if (priceListData.actionItems && priceListData.actionItems.length > 0) {
        setActionItems(priceListData.actionItems);
      }
    }
  }, [priceListData]);

  // 在表單編輯模式下初始化表單值
  useEffect(() => {
    if (isEditMode && form) {
      const initialValues: PriceListFormValues = {
        videoItems: videoItems.map(item => ({ price: item.price })),
        actionItems: actionItems.map(item => ({ price: item.price }))
      };
      form.setFieldsValue(initialValues);
    }
  }, [isEditMode, form, videoItems, actionItems]);

  const onFinish = async (values: PriceListFormValues) => {
    try {
      setLoading(true);
      // 將表單數據與原有spec數據合併
      const updatedVideoItems = videoItems.map((item, index) => ({
        name: item.name,
        spec: item.spec,
        price: values.videoItems[index].price
      }));

      const updatedActionItems = actionItems.map((item, index) => ({
        name: item.name,
        spec: item.spec,
        price: values.actionItems[index].price
      }));

      const priceListData = {
        videoItems: updatedVideoItems,
        actionItems: updatedActionItems
      };

      console.log('表單提交:', priceListData);

      try {
        // 這裡保留原來的保存邏輯，但應該考慮移到父組件中處理
        await updateDemandItem({
          item_id: groupIDPriceList,
          data: priceListData
        });

        message.success('價目表已成功更新');
        setShowPricelistModal(false);
      } catch (error: any) {
        console.error('儲存價目表時發生錯誤:', error);
        message.error('儲存價目表失敗，請稍後再試');
      }
    } catch (error) {
      console.error('提交表單時發生錯誤:', error);
      message.error('儲存失敗，請稍後再試');
    } finally {
      setLoading(false);
    }
  };

  const PriceItem: React.FC<PriceItemProps> = ({ specification, price, index, sectionName, isEditMode }) => {
    return (
      <div className="price-item">
        <span className="price-spec">{specification}</span>
        {isEditMode ? (
          <Form.Item name={[sectionName, index, 'price']} initialValue={price} className="price-value-form-item">
            <InputNumber
              className="price-value-input"
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        ) : (
          <span className="price-value">
            {price.toLocaleString('en-US')} up
          </span>
        )}
      </div>
    );
  };

  const SectionTitle: React.FC<SectionTitleProps> = ({ title, description }) => (
    <div className="section-title">
      <div className="section-title-container">
        <span className="section-title-text">{title}</span>
        <span className="section-description">{description}</span>
      </div>
    </div>
  );

  const TableHeader: React.FC = () => (
    <div className="table-header">
      <span className="header-text">規格</span>
      <span className="header-text">價格</span>
    </div>
  );

  const Divider: React.FC = () => <div className="divider"></div>;

  const Section: React.FC<SectionProps> = ({ title, description, items, sectionName, isEditMode }) => (
    <div className="price-section my-4">
      <SectionTitle title={title} description={description} />
      {isEditMode === false && <TableHeader />}
      <Divider />
      {items.map((item, index) => (
        <PriceItem
          key={index}
          specification={item.spec}
          price={item.price}
          index={index}
          sectionName={sectionName}
          isEditMode={isEditMode}
        />
      ))}
    </div>
  );

  return (
    <Modal
      open={showPricelistModal}
      onCancel={() => setShowPricelistModal(false)}
      footer={null}
      width={337}
      centered
      closable={true}
      className="price-calculation-modal"
      styles={{
        mask: {
          backgroundColor: "rgba(0, 0, 0, 0.8)"
        },
        content: {
          borderRadius: "10px",
          backgroundColor: "#FFFFFF"
        }
      }}
    >
      {initialLoading || isLoading ? (
        <div className="flex flex-col items-center justify-center p-8">
          <div className="text-center mb-4">載入價目表中...</div>
        </div>
      ) : (
        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className="price-list-container">
            <div className="modal-title">
              <h2>價目表設定</h2>
            </div>

            <Section
              title="親友影音製作"
              description={`親友影音製作費用將依照影片長度計價\n目前依照朗讀語音長度初估價格`}
              items={videoItems}
              sectionName="videoItems"
              isEditMode={isEditMode}
            />

            <Section
              title="親友動作製作"
              description="親友影音製作費用將依照動作內容計價"
              items={actionItems}
              sectionName="actionItems"
              isEditMode={isEditMode}
            />
          </div>
          {isEditMode && (
            <Button type="primary" htmlType="submit" className="w-full" loading={loading}>
              儲存
            </Button>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default Pricelist; 