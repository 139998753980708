import React, { createContext, useContext, ReactNode } from 'react';
import { useMqtt as useOriginalMqtt } from '../hooks/useMqtt';

// MQTT Context 返回值的接口定义
interface MqttContextValue {
  connectionStatus: string;
  messages: Record<string, any>;
  subscribe: (topic: string, callback?: (message: any) => void) => boolean;
  unsubscribe: (topic: string) => boolean;
  publish: (topic: string, message: string | Record<string, any>) => boolean;
  registerDemandCallback: (callback: (message: any) => void) => void;
  unregisterDemandCallback: (callback: (message: any) => void) => void;
  clearDemandCallbacks: () => void;
}

// 创建 MQTT Context
const MqttContext = createContext<MqttContextValue | null>(null);

// MQTT Provider Props 接口
interface MqttProviderProps {
  children: ReactNode;
  brokerUrl?: string;
  options?: Record<string, any>;
}

// MQTT Provider 组件
export const MqttProvider: React.FC<MqttProviderProps> = ({
  children,
  brokerUrl,
  options = {}
}) => {
  // 使用原始的 useMqtt hook
  const mqtt = useOriginalMqtt(brokerUrl, options);

  return (
    <MqttContext.Provider value={mqtt}>
      {children}
    </MqttContext.Provider>
  );
};

// 自定义 Hook，用于在组件中访问 MQTT Context
export const useMqtt = (): MqttContextValue => {
  const context = useContext(MqttContext);
  
  if (!context) {
    throw new Error('useMqtt 必須在 MqttProvider 內部使用');
  }
  
  return context;
};

export default MqttProvider; 