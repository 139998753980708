import { useState } from "react";
import { Typography, Checkbox, Upload, Form, message } from "antd";
import { UploadButton } from "./UploadButton";
const { Text } = Typography;

interface PhotoUploadProps {
  name: string;
  title?: string;
  refList?: string[];  // 參考照片的欄位名稱列表
  form?: any;          // Form 實例
}

export const PhotoUpload = ({ name, title = '照片', refList = [], form }: PhotoUploadProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleClick = (e: any) => {
    const checked = e.target.checked;
    setIsChecked(checked);

    if (checked && form) {
      // 按照優先順序尋找已上傳的照片
      const existingPhotos = refList
        .map(field => form.getFieldValue(field))
        .find(photos => photos && photos.length > 0);

      if (existingPhotos) {
        form.setFieldValue(name, existingPhotos);
        return;
      }
      message.warning('請先上傳照片!');
    } else if (!checked && form) {
      // 取消勾選時清空照片
      form.setFieldValue(name, []);
    }
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <Text className="!text-[15px]">{title}</Text>
        {refList.length > 0 && (
          <Checkbox
            className="!text-[15px]"
            checked={isChecked}
            onChange={handleClick}
          >同上</Checkbox>
        )}
      </div>
      <Text type="danger" className="!text-[13px] !text-[#FF0000]">
        ※ 提醒您 圖片解析度需達1080P以上、需為單獨照片、需為正面照片
      </Text>
      <Form.Item
        name={name}
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e?.fileList;
        }}
      >
        <Upload
          name={name}
          beforeUpload={() => false}
          accept=".jpg,.jpeg,.png"
          className={`
            max-w-full
            [&_.ant-upload-wrapper]:!w-full
            [&_.ant-upload]:!w-full
            [&_.ant-upload-list-item-image]:!object-contain
          `}
          listType="picture"
          maxCount={1}
          disabled={isChecked}
        >
          <UploadButton text="上傳圖片" />
        </Upload>
      </Form.Item>

    </>
  )
};

export default PhotoUpload;


