import { Carousel, Tabs, message, Spin } from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";
import { getGroup } from "../services/api";

interface Partner {
    id: number;
    category: string;
    logo: string;
    url: string;
}

interface Platform {
    id: string;
    url: string;
    type: string;
}

interface CompanyInfo {
    name: string;
    logo: string;
}

interface MainData {
    bannerImages: string[];
    mobileContentImages: string[];
    videoUrl: string;
    partners: Partner[];
}

interface FooterData {
    serviceDescription: string;
    platforms: Platform[];
}

interface LayoutProps {
    children: React.ReactNode;
    companyInfo: CompanyInfo;
    mainData: MainData;
    footerData: FooterData;
    loading: boolean;
}

const GROUPS = {
    FOOTER: 'footer',
    MAIN: 'main',
    HEADER: 'header',
};

const MAIN_ITEMS = {
    PARTNERS: 'partners_info',
    BANNER: 'banner',
    MOBILE: 'mobile_content',
    VIDEO: 'video_content',
};

const getYoutubeVideoId = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
};

export default function Layout({ children, companyInfo, mainData, footerData, loading }: LayoutProps): JSX.Element {
    const navigate = useNavigate();
    const [activeRegion, setActiveRegion] = useState<string>("");
    const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
    const { bannerImages, partners } = mainData;
    const { serviceDescription, platforms } = footerData;

    useEffect(() => {
        if (partners.length > 0) {
            setActiveRegion(partners[0].category);
        }
    }, [partners]);

    // 根據平台類型獲取圖標和URL
    const getPlatformInfo = (type: string) => {
        const platformData = platforms.find(p => p.type === type);
        return {
            url: platformData?.url || "#",
            icon: type === "Youtube" ? "/yt.png" : 
                  type === "Tiktok" ? "/tiktok.png" : 
                  type === "Instagram" ? "/Instagram.png" : 
                  type === "Facebook" ? "/Facebook.png" : ""
        };
    };

    return (
        <Spin spinning={loading} tip="載入中..." size="large">
            <div className="flex flex-col lg:flex-row min-h-screen w-full bg-white max-w-[1920px] mx-auto">
                {/* Mobile Header - only show on small screen */}
                <header className="fixed top-0 left-0 right-0 bg-white z-50 lg:hidden">
                    <div className="h-[80px] flex items-center justify-between px-4 border-b border-gray-200">
                        {/* 漢堡菜單按鈕 - 使用 antd 圖標 */}
                        <button
                            className="w-10 h-10 flex justify-center items-center focus:outline-none"
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        >
                            {mobileMenuOpen ? (
                                <CloseOutlined style={{ fontSize: '24px' }} />
                            ) : (
                                <MenuOutlined style={{ fontSize: '24px' }} />
                            )}
                        </button>
                        <div className="flex-1 flex justify-center items-center space-x-2">
                            {companyInfo.logo && (
                                <img
                                    src={companyInfo.logo}
                                    alt={companyInfo.name}
                                    width={40}
                                    height={40}
                                    className="rounded-full object-cover"
                                />
                            )}
                            <span className="text-[20px] font-semibold tracking-[0.3em] font-inter">
                                {companyInfo.name}
                            </span>
                        </div>

                    </div>

                    {/* 手機版導航菜單 */}
                    <div
                        className={`lg:hidden fixed top-[80px] left-0 right-0 bg-white z-40 border-b border-gray-200 shadow-lg transition-all duration-300 ${mobileMenuOpen ? 'max-h-[300px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}
                    >
                        <nav className="flex flex-col px-4 py-2">
                            <a
                                href="#"
                                className="text-gray-700 hover:text-[#FFB81F] text-center font-inter text-xl tracking-[0.2em] py-4 border-b border-gray-100 w-full"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                關於我們
                            </a>
                            <a
                                href="#"
                                className="text-gray-700 hover:text-[#FFB81F] text-center font-inter text-xl tracking-[0.2em] py-4 border-b border-gray-100 w-full"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                最新消息
                            </a>
                            <a
                                href="/combo"
                                className="text-gray-700 hover:text-[#FFB81F] text-center font-inter text-xl tracking-[0.2em] py-4 border-b border-gray-100 w-full"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                服務方案
                            </a>
                            <a
                                href="#"
                                className="text-gray-700 hover:text-[#FFB81F] text-center font-inter text-xl tracking-[0.2em] py-4 w-full"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                加入我們
                            </a>
                        </nav>
                    </div>
                </header>

                {/* Left content area - hidden on small screen and show on large screen */}
                <div className="hidden lg:block lg:w-[320px] border-r border-gray-200 py-10">
                    <div className="flex justify-center flex-col space-y-4 cursor-pointer" onClick={() => navigate('/')}>
                        <div className="flex justify-center items-center space-x-2 mb-8">
                            {companyInfo.logo && (
                                <img 
                                    src={companyInfo.logo} 
                                    alt={companyInfo.name} 
                                    width={70} 
                                    height={70}
                                    className="rounded-full object-cover"
                                />
                            )}
                            <span className="text-[28px] font-semibold leading-[38.73px] tracking-[0.3em] font-inter">
                                {companyInfo.name}
                            </span>
                        </div>

                        <nav className="flex flex-col space-y-1">
                            <a href="#" className="text-gray-700 hover:text-[#FFB81F] hover:bg-gray-50 text-center font-inter text-2xl tracking-[0.3em] leading-[29.05px] py-5 rounded-lg transition-all duration-300">
                                關於我們
                            </a>
                            <a href="#" className="text-gray-700 hover:text-[#FFB81F] hover:bg-gray-50 text-center font-inter text-2xl tracking-[0.3em] leading-[29.05px] py-5 rounded-lg transition-all duration-300">
                                最新消息
                            </a>
                            <a href="/combo" className="text-gray-700 hover:text-[#FFB81F] hover:bg-gray-50 text-center font-inter text-2xl tracking-[0.3em] leading-[29.05px] py-5 rounded-lg transition-all duration-300">
                                服務方案
                            </a>
                            <a href="#" className="text-gray-700 hover:text-[#FFB81F] hover:bg-gray-50 text-center font-inter text-2xl tracking-[0.3em] leading-[29.05px] py-5 rounded-lg transition-all duration-300">
                                加入我們
                            </a>
                        </nav>
                    </div>
                </div>

                {/* Middle content area */}
                <div className="w-full h-full lg:flex-1 min-w-0 lg:mt-[50px]">
                    {/* Carousel component */}
                    <div className={`relative lg:mt-0 transition-all duration-300 ${mobileMenuOpen ? 'mt-[240px]' : 'mt-[80px]'}`}>
                        <Carousel
                            autoplay
                            className="!h-full lg:rounded-none lg:m-0 m-3 rounded-lg"
                            dots={{ className: 'dots-bottom' }}
                        >
                            {bannerImages.map((url, index) => (
                                <div key={index} className="h-full aspect-[1180/595] relative">
                                    <img
                                        src={url}
                                        alt={`banner${index + 1}`}
                                        className="lg:rounded-none rounded-lg object-cover w-full h-full"
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>

                    {/* Render children */}
                    {children}
                </div>

                {/* Right content area */}
                <div className="w-full lg:w-[420px] border-t lg:border-t-0 lg:border-l border-gray-200 p-4">
                    <h2 className="text-[24px] lg:text-[24px] text-center lg:tracking-[0.3em] lg:leading-[29.05px] font-inter mb-4 font-lexend font-semibold leading-[28px] lg:font-normal">
                        合作廠商
                    </h2>

                    {/* Region classification */}
                    <Tabs
                        activeKey={activeRegion}
                        items={Array.from(new Set(partners.map(partner => partner.category))).map(category => ({
                            key: category,
                            label: category,
                        }))}
                        onChange={(key) => {
                            setActiveRegion(key);
                        }}
                        className="partner-tabs"
                    />

                    {/* Logo grid */}
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4">
                        {partners
                            .filter(partner => partner.category === activeRegion)
                            .map((partner) => (
                                <div
                                    key={partner.id}
                                    className="border border-gray-100 rounded-lg flex items-center justify-center h-20 sm:h-24"
                                >
                                    <a href={partner.url} target="_blank" rel="noopener noreferrer" className="relative w-full h-full">
                                        <img
                                            src={partner.logo}
                                            alt="合作廠商"
                                            className="object-contain w-full h-full"
                                        />
                                    </a>
                                </div>
                            ))}
                    </div>
                </div>
            </div>

            {/* Footer */}
            <footer className="bg-[#EAE6D9] p-6 mt-[20px]">
                <div className="text-center">
                    <div className="flex flex-col items-center">
                        <div className="flex justify-center items-center">
                            {companyInfo.logo && (
                                <img
                                    src={companyInfo.logo}
                                    alt={companyInfo.name}
                                    className="h-[54px] w-[58px] mr-2 rounded-full object-cover"
                                />
                            )}
                            <h2 className="text-[24px] font-bold">{companyInfo.name}</h2>
                        </div>
                        <p className="text-[16px]">{serviceDescription}</p>
                    </div>
                    <div className="flex justify-center mt-4">
                        {platforms.map((platform) => (
                            <a key={platform.id} href={platform.url} className="mr-2">
                                <button className="flex justify-center hover:opacity-75 active:opacity-50 transition-opacity duration-200 h-[40px] w-[40px] rounded-full items-center bg-white">
                                    <img 
                                        src={
                                            platform.type === "Youtube" ? "/yt.png" : 
                                            platform.type === "Tiktok" ? "/tiktok.png" : 
                                            platform.type === "Instagram" ? "/Instagram.png" : 
                                            platform.type === "Facebook" ? "/Facebook.png" : ""
                                        } 
                                        alt={`${platform.type.toLowerCase()}.png`}
                                        className="h-[25px]" 
                                    />
                                </button>
                            </a>
                        ))}
                    </div>
                </div>
                <hr className="my-[20px] border-t border-gray-300" />
                <p className="text-center text-sm">
                    ©2024 {companyInfo.name}. All rights reserved
                </p>
            </footer>
        </Spin>
    );
}
