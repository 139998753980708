
const isTestMode = false;
export const gethealtechConfig = () => {
  // console.log('isTestMode', isTestMode);
  if (isTestMode) {
    return {
      username: 'healtech.ai.dev',
      password: 'healtech.ai.dev',
      groupID: 'ec483750-b106-49b6-b926-96cad96c7720', // 測試
      groupIDPriceList: '0159addf-610c-4477-af5f-e12887753d90',
    }
  } else {
    return {
      username: 'healtech.ai',
      password: 'healtech.ai.admin',
      groupID: '6fbba4f2-d965-452a-8ec4-75e780317b25', // 正式
      groupIDPriceList: 'f6cb568e-0bdf-49f1-922c-9c12ae442853'
    }
  }
}
