import { Modal } from 'antd';

interface ConfirmProps {
  open: boolean;
  title?: string;
  content: string;
  isLoading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const Confirm = ({
  open,
  title = '確認',
  content,
  isLoading,
  onConfirm,
  onCancel
}: ConfirmProps) => {
  return (
    <Modal
      open={open}
      title={title}
      onOk={onConfirm}
      onCancel={onCancel}
      okText={isLoading ? '處理中...' : '確認'}
      cancelText="取消"
      className="confirm-modal text-[#EE1A26]"
    >
      {content}
    </Modal>
  );
};
