import { request } from '../utils';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// 獲取 Access Token

export const getAccessToken = async () => {
    try {
      const response = await request.get(`${SERVER_URL}/private/property/ecommerce/jwt`);
      return response.data.access_token as string;
    } catch (error) {
      console.error('獲取存取令牌失敗:', error);
      throw new Error('Unable to fetch access token');
    }
  };

  // temporary access token
export const getTemporaryAccessToken = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('grant_type', 'password');
      formData.append('username', 'healtech.ai');
      formData.append('password', 'healtech.ai.admin');
      
      const response = await request.post('https://os.api.scfg.io/auth/login', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      
      return response.data.access_token as string;
    } catch (error) {
      console.error('獲取存取令牌失敗:', error);
      throw new Error('Unable to fetch access token');
    }
};

  // 獲取伺服器 URL
export const getServerUrl = async () => {
    try {
      const response = await request.get(`${SERVER_URL}/private/property/ecommerce/profile`);
      return response.data.server_url as string;
    } catch (error) {
      console.error('獲取伺服器 URL 失敗:', error);
      throw new Error('Unable to fetch server URL');
    }
  };

// 新增：获取认证信息和服务器配置
export const getAuthConfig = async () => {
  try {
    const [accessToken, serverUrl] = await Promise.all([
      getAccessToken(),
      getServerUrl()
    ]);

    return {
      accessToken,
      serverUrl
    };
  } catch (error) {
    console.error('获取认证配置失败:', error);
    throw new Error('Failed to fetch authentication configuration');
  }
};