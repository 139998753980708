import { useState, useEffect, useCallback } from "react";
import { Typography, Radio, Upload, Form } from "antd";
import { UploadButton } from "./UploadButton";
const { Text } = Typography;

// 防抖函数，延迟执行回调
const debounce = (fn: Function, delay: number) => {
  let timer: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

interface AudioUploadProps {
  name: string;
  form: any;
  onAudioChange?: (audioInfo: { duration: number }) => void;
  required?: boolean;
}

export const AudioUpload = ({ name, form, onAudioChange, required = false }: AudioUploadProps) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioRecorder, setAudioRecorder] = useState<MediaRecorder | null>(null);
  const [recordingType, setRecordingType] = useState('upload');
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  // 組件掛載時檢查是否已有上傳的文件
  useEffect(() => {
    const existingFiles = form.getFieldValue(name);
    if (existingFiles?.length > 0 && existingFiles[0]?.url) {
      setAudioUrl(existingFiles[0].url);
    }
  }, [form, name]);

  // 使用 AudioContext 更準確地獲取音頻時長
  const getAccurateAudioDuration = useCallback(async (blob: Blob): Promise<number> => {
    try {
      console.log('[AudioUpload] 使用 AudioContext 精確獲取音頻時長');
      const audioContext = new AudioContext();
      const audioBuffer = await blob.arrayBuffer();
      const decodedData = await audioContext.decodeAudioData(audioBuffer);
      const duration = decodedData.duration;
      console.log('[AudioUpload] 獲取到準確時長:', duration);
      return parseFloat(duration.toFixed(1));
    } catch (error) {
      console.error('[AudioUpload] AudioContext 解析音頻失敗:', error);
      // 如果解析失敗，返回預設值
      return 120; // 預設2分鐘
    }
  }, []);

  // 使用 useCallback 包装获取音频长度方法 (備用方法)
  const getAudioDuration = useCallback((url: string): Promise<number> => {
    return new Promise((resolve) => {
      console.log('[AudioUpload] 開始檢測音頻時長:', url);
      const audio = new Audio();
      audio.src = url;

      // 檢查是否為WAV文件
      const isWavFile = url.includes('.wav') || url.includes('audio/wav');
      console.log('[AudioUpload] 是否為WAV檔案:', isWavFile);

      audio.addEventListener('loadedmetadata', () => {
        // 檢查是否為WAV文件且時長為Infinity或NaN，這是瀏覽器對某些WAV文件的已知問題
        if (isWavFile && (!isFinite(audio.duration) || isNaN(audio.duration))) {
          console.log('[AudioUpload] WAV文件時長檢測問題，使用預設值120秒');
          // 對於WAV文件，如果無法獲取準確時長，使用預設值（例如120秒/2分鐘）
          resolve(120);
        } else {
          console.log('[AudioUpload] 音頻時長檢測成功:', audio.duration);
          resolve(audio.duration);
        }
      });

      // 如果加載失敗或超時，返回預設值
      audio.addEventListener('error', () => {
        console.error('[AudioUpload] 獲取音頻長度失敗', audio.error);
        // 如果是WAV文件，使用預設時長
        if (isWavFile) {
          console.log('[AudioUpload] WAV文件檢測失敗，使用預設值120秒');
          resolve(120);
        } else {
          resolve(0);
        }
      });

      // 添加超時處理
      const timeout = setTimeout(() => {
        console.warn('[AudioUpload] 獲取音頻時長超時');
        if (isWavFile) {
          resolve(120); // WAV文件使用預設時長
        } else {
          resolve(0);
        }
      }, 3000); // 3秒超時

      // 清除超時計時器
      audio.addEventListener('loadedmetadata', () => clearTimeout(timeout));
      audio.addEventListener('error', () => clearTimeout(timeout));
    });
  }, []);

  // 创建防抖处理函数，减少状态更新的频率
  const debouncedAudioChange = useCallback(
    debounce((duration: number) => {
      if (onAudioChange) {
        console.log('[AudioUpload] 調用 onAudioChange, 音頻時長:', duration);
        onAudioChange({ duration });
      }
    }, 300),
    [onAudioChange]
  );

  const startRecording = async () => {
    setAudioUrl(null);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const chunks: BlobPart[] = []; // 創建數組收集音頻數據片段

      setAudioRecorder(recorder);

      // 收集音頻數據片段
      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      // 當錄音結束時處理數據
      recorder.onstop = async () => {
        const audioBlob = new Blob(chunks, { type: 'audio/wav' });
        const url = URL.createObjectURL(audioBlob);
        setAudioUrl(url);
        console.log('[AudioUpload] 錄音完成，創建URL:', url);

        // 使用 AudioContext 獲取準確時長
        const duration = await getAccurateAudioDuration(audioBlob);
        console.log('[AudioUpload] 錄音時長 (AudioContext):', duration);
        debouncedAudioChange(duration);

        const audioFile = new File([audioBlob], 'recording.wav', {
          type: 'audio/wav'
        });

        const fileList = [{
          uid: '-1',
          name: 'recording.wav',
          status: 'done',
          url: url,
          originFileObj: audioFile,
          duration: duration, // 保存音頻長度
        }];

        form.setFieldValue(name, fileList);
        console.log('[AudioUpload] 設置錄音文件到表單:', name, fileList);

        // 手動觸發表單驗證
        setTimeout(() => {
          form.validateFields([name]);
        }, 100);
      };

      recorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error('錄音失敗:', error);
    }
  };

  const stopRecording = () => {
    if (audioRecorder && audioRecorder.state !== 'inactive') {
      audioRecorder.stop();
      audioRecorder.stream.getTracks().forEach(track => track.stop());
      setIsRecording(false);
    }
  };

  // 處理文件上傳後的音頻分析
  const handleUploadChange = useCallback((info: any) => {
    console.log('[AudioUpload] handleUploadChange 被觸發', info);

    if (info.file && info.file.status !== 'removed') {
      const file = info.file.originFileObj || info.file;
      console.log('[AudioUpload] 檔案狀態:', info.file.status, '檔案名稱:', file?.name);

      // 處理檔名過長問題
      if (file instanceof File) {
        const originalName = file.name;
        const maxLength = 20; // 最大檔名長度

        // 檢查檔名是否過長
        if (originalName.length > maxLength) {
          // 分離檔名和副檔名
          const lastDotIndex = originalName.lastIndexOf('.');
          const extension = lastDotIndex !== -1 ? originalName.substring(lastDotIndex) : '';
          const nameWithoutExt = lastDotIndex !== -1 ? originalName.substring(0, lastDotIndex) : originalName;

          // 計算新檔名的長度（預留副檔名的空間）
          const newNameLength = maxLength - extension.length;
          // 截斷檔名並加上副檔名
          const truncatedName = `${nameWithoutExt.substring(0, newNameLength)}${extension}`;

          // 創建新的 File 對象，使用截斷後的檔名
          const newFile = new File([file], truncatedName, { type: file.type });

          // 更新 info.file 和 info.fileList
          info.file.originFileObj = newFile;
          if (info.fileList && info.fileList.length > 0) {
            info.fileList[0].originFileObj = newFile;
            info.fileList[0].name = truncatedName;
          }

          console.log(`檔名已從 "${originalName}" 截斷為 "${truncatedName}"`);
        }

        // 使用AudioContext獲取準確時長
        const isWavFile = file.name.includes('.wav') || file.type.includes('audio/wav');
        if (isWavFile) {
          // 對於WAV文件使用更準確的方法
          getAccurateAudioDuration(file).then(duration => {
            console.log('[AudioUpload] WAV檔案時長 (AudioContext):', duration);
            debouncedAudioChange(duration);

            // 更新表單值
            if (info.fileList && info.fileList.length > 0) {
              info.fileList[0].duration = duration;
              form.setFieldValue(name, info.fileList);

              // 手動觸發表單驗證
              setTimeout(() => {
                form.validateFields([name]);
              }, 100);
            }
          });

          // 創建URL用於預覽
          const url = URL.createObjectURL(file);
          setAudioUrl(url);
          return;
        }
      }

      // 對於非WAV文件使用原來的方法
      const url = file instanceof File ? URL.createObjectURL(file) : (info.file.url || null);

      if (url) {
        setAudioUrl(url);
        console.log('[AudioUpload] 設置音頻URL:', url);

        // 获取音频长度
        getAudioDuration(url).then(duration => {
          console.log('[AudioUpload] 獲取音頻時長:', duration);
          debouncedAudioChange(duration);

          // 確保表單值被更新
          if (info.fileList && info.fileList.length > 0) {
            // 將時長添加到文件對象中
            info.fileList[0].duration = duration;

            // 確保更新表單值
            form.setFieldValue(name, info.fileList);
            console.log('[AudioUpload] 更新表單值:', name, info.fileList);

            // 手動觸發表單驗證
            setTimeout(() => {
              form.validateFields([name]);
            }, 100);
          }
        });
      }
    } else if (info.fileList.length === 0) {
      // 如果清空了上傳列表
      console.log('[AudioUpload] 清空上傳列表');
      setAudioUrl(null);
      debouncedAudioChange(0);
    }
  }, [getAudioDuration, getAccurateAudioDuration, debouncedAudioChange, form, name]);

  // 渲染音頻預覽組件
  const renderAudioPreview = () => {
    if (!audioUrl) return null;

    return (
      <div className="mt-2">
        <audio
          src={audioUrl}
          controls
          className="w-[300px] max-w-md"
        />
      </div>
    );
  };

  return (
    <>
      <Text className="!text-[15px]">朗讀語音</Text>
      <Text type="danger" className="!text-[13px] !text-[#FF0000]">
        ※ 盡可能使用需求台詞模仿親友的語氣、語調
      </Text>
      <Radio.Group
        value={recordingType}
        onChange={(e) => {
          const newType = e.target.value;
          console.log(`[AudioUpload] 切換模式從 ${recordingType} 到 ${newType}`);

          // 如果模式真的變更了
          if (newType !== recordingType) {
            // 1. 清空現有的文件列表
            form.setFieldValue(name, []);

            // 2. 清空音頻URL
            setAudioUrl(null);

            // 3. 如果有正在錄音的，停止它
            if (isRecording && audioRecorder) {
              audioRecorder.stop();
              audioRecorder.stream.getTracks().forEach(track => track.stop());
              setIsRecording(false);
            }

            // 4. 通知父組件音頻已清空
            debouncedAudioChange(0);

            console.log(`[AudioUpload] 已清空 ${recordingType} 模式的資料`);
          }

          // 設置新的模式
          setRecordingType(newType);
        }}
        className="flex gap-8 mb-2"
      >
        <Radio value="upload" className="!text-[15px]">上傳音檔</Radio>
        <Radio value="record" className="!text-[15px]">一鍵錄音</Radio>
      </Radio.Group>
      <Form.Item
        name={name}
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e?.fileList;
        }}
        rules={required ? [{
          required: true,
          message: '請上傳朗讀語音檔案',
          validator: (_, value) => {
            console.log('[AudioUpload] 驗證音頻文件:', value);

            // 檢查是否有文件上傳且狀態不是'removed'
            if (value && value.length > 0 && value[0].status !== 'removed') {
              return Promise.resolve();
            }

            // 檢查 audioUrl 是否存在，這表示已經有上傳或錄製的音頻
            if (audioUrl) {
              return Promise.resolve();
            }

            return Promise.reject(new Error('請上傳朗讀語音檔案'));
          }
        }] : []}
        noStyle
      >
        {recordingType === 'upload' ? (
          <Upload
            name={name}
            beforeUpload={() => false}
            accept=".mp3,.wav,.m4a,.aac"
            className={`
              max-w-full
              [&_.ant-upload-wrapper]:!w-full
              [&_.ant-upload]:!w-full
            `}
            listType="text"
            maxCount={1}
            onChange={handleUploadChange}
            onRemove={() => {
              setAudioUrl(null);
              debouncedAudioChange(0);
              return true;
            }}
          >
            <UploadButton text="上傳音檔" />
          </Upload>
        ) : null}
      </Form.Item>

      <div className="flex flex-col gap-4">
        {recordingType === 'record' && (
          <button
            type="button"
            onClick={isRecording ? stopRecording : startRecording}
            className={`px-6 py-2 rounded-lg ${isRecording
              ? 'bg-red-500 hover:bg-red-600'
              : 'bg-blue-500 hover:bg-blue-600'
              } text-white transition-colors`}
          >
            {isRecording ? '停止錄音' : '開始錄音'}
          </button>
        )}

        {/* 統一的音頻預覽 */}
        {renderAudioPreview()}
      </div>
    </>
  );
};


