import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { getAuthConfig, getTemporaryAccessToken } from '../services/api';

const callApi: AxiosInstance = axios.create({
  timeout: 200000,
});

callApi.interceptors.request.use(async (config) => {
  try {
    const temporaryAccessToken = await getTemporaryAccessToken();
    config.baseURL = 'https://os.api.scfg.io';
    config.headers.Authorization = `Bearer ${temporaryAccessToken}`;
    
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    }
    
    return config;
  } catch (error) {
    return Promise.reject(error); 
  }
}, (error: any) => {
  return Promise.reject(error);
});

callApi.interceptors.response.use((response: AxiosResponse) => {
  response.data.status = response.status;
  return response;
}, (error: any) => {
  if (error.response) {
    error.response.data.status = error.response.status;
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error);
});

export default callApi;
