import { message } from "antd";
import { useState, useEffect } from "react";
import YouTube from "react-youtube";

import ServiceContent from "./assets/service-content.jpg";
import ServiceContentMobile from "./assets/service-content-mobile.jpg";
import { Link } from "react-router-dom";
import { getGroup } from "./services/api";

const GROUPS = {
  MAIN: 'main',
};

const MAIN_ITEMS = {
  BANNER: 'banner',
  MOBILE: 'mobile_content',
  VIDEO: 'video_content',
};

interface MainData {
  bannerImages: string[];
  mobileContentImages: string[];
  videoUrl: string;
  partners: Array<{
    id: number;
    category: string;
    logo: string;
    url: string;
  }>;
}

interface HomeProps {
  mainData: MainData;
}

const getYoutubeVideoId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
};

export default function Home({ mainData }: HomeProps): JSX.Element {
  const { mobileContentImages, videoUrl } = mainData;

  return (
    <>
      {mobileContentImages.length > 0 && (
        mobileContentImages.map((url, index) => (
          <img
            key={index}
            src={url}
            alt={`mobile content ${index + 1}`}
            className="w-full h-full object-cover"
            width={1920}
            height={1080}
          />
        ))
      )}

      <Link className="flex justify-center overflow-x-auto" to="/combo">
        <img
          src={ServiceContent}
          alt="combo.png"
          className="hidden xl:block object-cover h-[420px]"
        />
        <img
          src={ServiceContentMobile}
          alt="combo.png"
          className="block xl:hidden object-cover"
        />
      </Link>

      {videoUrl && getYoutubeVideoId(videoUrl) && (
        <div className="relative w-full h-[400px] flex justify-center">
          <YouTube
            videoId={getYoutubeVideoId(videoUrl) || ''}
            opts={{
              width: '100%',
              height: '100%',
              playerVars: {
                autoplay: 0,
              },
            }}
            className="absolute top-0 left-0 w-full h-full"
          />
        </div>
      )}
    </>
  );
}
