import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { message } from "antd";
import Home from "./Home";
import Recruit from "./Recruit";
import Combo from "./Combo";
import Layout from "./components/Layout";
import { getGroup } from "./services/api";

const GROUPS = {
  HEADER: 'header',
  MAIN: 'main',
  FOOTER: 'footer',
};

const MAIN_ITEMS = {
  PARTNERS: 'partners_info',
  BANNER: 'banner',
  MOBILE: 'mobile_content',
  VIDEO: 'video_content',
};

interface CompanyInfo {
  name: string;
  logo: string;
}

interface Partner {
  id: number;
  category: string;
  logo: string;
  url: string;
}

interface Platform {
  id: string;
  url: string;
  type: string;
}

interface MainData {
  bannerImages: string[];
  mobileContentImages: string[];
  videoUrl: string;
  partners: Partner[];
}

interface FooterData {
  serviceDescription: string;
  platforms: Platform[];
}

const App: React.FC = () => {
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>({ name: "", logo: "" });
  const [mainData, setMainData] = useState<MainData>({
    bannerImages: [],
    mobileContentImages: [],
    videoUrl: "",
    partners: []
  });
  const [footerData, setFooterData] = useState<FooterData>({
    serviceDescription: "",
    platforms: []
  });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true);
        const groupsResponse = await getGroup();
        
        // 獲取 HEADER 組數據
        const headerGroup = groupsResponse.find((group: any) => group.name === GROUPS.HEADER);
        if (headerGroup) {
          await fetchHeaderData(headerGroup.id);
        }

        // 獲取 MAIN 組數據
        const mainGroup = groupsResponse.find((group: any) => group.name === GROUPS.MAIN);
        if (mainGroup) {
          await fetchMainData(mainGroup.id);
        }

        // 獲取 FOOTER 組數據
        const footerGroup = groupsResponse.find((group: any) => group.name === GROUPS.FOOTER);
        if (footerGroup) {
          await fetchFooterData(footerGroup.id);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        message.error('無法獲取數據');
      } finally {
        setLoading(false);
      }
    };

    const fetchHeaderData = async (groupId: string) => {
      try {
        const groupDetail = await getGroup(groupId);
        if (groupDetail.items && groupDetail.items.length > 0) {
          const companyInfoItem = groupDetail.items
            .filter((item: { name: string; created_at: string }) => item.name === 'company_info')
            .sort((a: { created_at: string }, b: { created_at: string }) =>
              new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0];

          if (companyInfoItem) {
            const name = companyInfoItem.information?.companyName || "";
            const logo = companyInfoItem.blobs && companyInfoItem.blobs.length > 0 ?
              companyInfoItem.blobs[0].url : "";

            setCompanyInfo({ name, logo });
          }
        }
      } catch (error) {
        console.error('Failed to fetch header data:', error);
      }
    };

    const fetchMainData = async (groupId: string) => {
      try {
        const groupDetail = await getGroup(groupId);
        if (groupDetail.items && groupDetail.items.length > 0) {
          // 按類型分類並僅獲取最新的一個
          const itemsByType: Record<string, any> = {};
          groupDetail.items.forEach((item: { name: string; created_at: string }) => {
            if (!itemsByType[item.name] ||
              new Date(item.created_at) > new Date(itemsByType[item.name].created_at)) {
              itemsByType[item.name] = item;
            }
          });

          // 獲取橫幅圖片
          const bannerImages = itemsByType[MAIN_ITEMS.BANNER]?.blobs?.map(
            (blob: { url: string }) => blob.url
          ) || [];

          // 獲取移動內容圖片
          const mobileContentImages = itemsByType[MAIN_ITEMS.MOBILE]?.blobs?.map(
            (blob: { url: string }) => blob.url
          ) || [];

          // 獲取影片 URL
          const videoUrl = itemsByType[MAIN_ITEMS.VIDEO]?.information?.videoUrl || "";

          // 獲取合作夥伴
          const partners = itemsByType[MAIN_ITEMS.PARTNERS]?.information?.partners || [];

          setMainData({
            bannerImages,
            mobileContentImages,
            videoUrl,
            partners
          });
        }
      } catch (error) {
        console.error('Failed to fetch main data:', error);
      }
    };

    const fetchFooterData = async (groupId: string) => {
      try {
        const groupDetail = await getGroup(groupId);
        if (groupDetail.items && groupDetail.items.length > 0) {
          const footerInfoItem = groupDetail.items
            .filter((item: { name: string; created_at: string }) => item.name === 'footer_info')
            .sort((a: { created_at: string }, b: { created_at: string }) =>
              new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0];

          if (footerInfoItem && footerInfoItem.information) {
            const serviceDescription = footerInfoItem.information.serviceDescription || "";
            const platforms = footerInfoItem.information.platforms || [];

            setFooterData({
              serviceDescription,
              platforms
            });
          }
        }
      } catch (error) {
        console.error('Failed to fetch footer data:', error);
      }
    };

    fetchAllData();
  }, []);

  useEffect(() => {
    if (companyInfo.name) {
      document.title = companyInfo.name;
    }
  }, [companyInfo.name]);

  return (
    <Layout 
      companyInfo={companyInfo} 
      mainData={mainData} 
      footerData={footerData}
      loading={loading}
    >
      <Routes>
        <Route path="/" element={<Home mainData={mainData} />} />
        <Route path="/recruit" element={<Recruit />} />
        <Route path="/combo" element={<Combo />} />
      </Routes>
    </Layout>
  );
};

export default App;
