import { request } from '../utils';
import callApi from "../utils/callApi";
import { gethealtechConfig } from '../config/config';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// 獲取 Access Token

export const getAccessToken = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/ecommerce/jwt`);
    return response.data.access_token as string;
  } catch (error) {
    console.error('獲取存取令牌失敗:', error);
    throw new Error('Unable to fetch access token');
  }
};

// temporary access token
export const getTemporaryAccessToken = async () => {
  try {
    const { username, password } = gethealtechConfig();
    const formData = new URLSearchParams();
    formData.append('grant_type', 'password');
    formData.append('username', username);
    formData.append('password', password);

    const response = await request.post('https://os.api.scfg.io/auth/login', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return response.data.access_token as string;
  } catch (error) {
    console.error('獲取存取令牌失敗:', error);
    throw new Error('Unable to fetch access token');
  }
};

// 獲取伺服器 URL
export const getServerUrl = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/ecommerce/profile`);
    return response.data.server_url as string;
  } catch (error) {
    console.error('獲取伺服器 URL 失敗:', error);
    throw new Error('Unable to fetch server URL');
  }
};

// 新增：获取认证信息和服务器配置
export const getAuthConfig = async () => {
  try {
    const [accessToken, serverUrl] = await Promise.all([
      getAccessToken(),
      getServerUrl()
    ]);

    return {
      accessToken,
      serverUrl
    };
  } catch (error) {
    console.error('获取认证配置失败:', error);
    throw new Error('Failed to fetch authentication configuration');
  }
};

interface DemandItem {
  group_id: string;
  data: any;
}
// [創建] item: 需求單 (name, description)
export const createDemandItem = async ({ group_id, data }: DemandItem) => {
  try {
    console.log('[createDemandItem] group_id', group_id);
    console.log('[createDemandItem] data', data);

    const response = await callApi.post(`/group/${group_id}`, data);
    return response.data;
  } catch (error) {
    console.error('[創建] 需求單 失敗:', error);
    throw error;
  }
}

interface UploadBlobToItem {
  item_id: string;
  file: any;
}
// [上傳] blob to item
export const uploadBlobToItem = async (
  { item_id, file }: UploadBlobToItem
) => {
  try {
    const formData = new FormData();

    if (file?.type === 'application/json') {
      formData.append('file', file, `demand-${Date.now()}.json`); // 添加檔案名稱
    } else {
      const blob = await changeBlobUrl2Blob(file.url);
      if (blob) {
        formData.append('file', blob, file.name); // 添加檔案名稱
      }
    }
    const response = await callApi.post(`/item/${item_id}`, formData, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      }
    });
    return response.data;
  } catch (error) {
    console.error('[上傳] blob to item 失敗:', error);
    throw error;
  }
}

interface UpdateDemandItem {
  item_id: string;
  data: any;
};
// [更新] item: 需求單 (information)
export const updateDemandItem = async (
  { item_id, data }: UpdateDemandItem
) => {
  try {
    const response = await callApi.patch(`/item/${item_id}/information`, data);
    return response.data;
  } catch (error) {
    console.error('[更新] item 失敗:', error);
    throw error;
  }
}

// [取得] 需求單列表
export const getDemandList = async (group_id: string) => {
  try {
    const response = await callApi.get(`/group/${group_id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

// 將 Blob URL 轉換為 Blob
export const changeBlobUrl2Blob = async (blobUrl: string) => {
  try {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('changeBlobUrl2Blob error:', error);
    return null;
  }
};

// [查詢] 需求單
export const searchDemandItem = async (item_id: string) => {
  try {
    const response = await callApi.get(`/item/${item_id}`);
    return response.data;
  } catch (error) {
    console.error('[查詢] 需求單 失敗:', error);
    throw error;
  }
}

// [查詢] 需求單列表
export const searchDemandByName = async (name: string) => {
  try {
    const { groupID } = gethealtechConfig();
    const response = await callApi.get(`/group/${groupID}`);
    const items = response.data?.items;
    console.log('items', response);
    const filteredItems = items?.filter((item: any) => item.name === name) || [];
    console.log('filteredItems', filteredItems);
    return filteredItems;
  } catch (error) {
    throw error;
  }
}

// 刪除需求單
export const deleteDemandItemBlob = async (item_id: string, blob_id: string) => {
  try {
    const response = await callApi.delete(`/item/${item_id}/blobs/${blob_id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

// [更新] 需求單 (name)
export const updateDemandName = async (item_id: string, name: string) => {
  try {
    const response = await callApi.patch(`/item/${item_id}/name?name=${name}`);
    return response.data;
  } catch (error) {
    console.error('[更新] 需求單 (name) 失敗:', error);
    throw error;
  }
}

// [取得] 需求單項目
export const getItemById = async (item_id: string) => {
  try {
    const response = await callApi.get(`/item/${item_id}`);
    return response.data;
  } catch (error) {
    console.error('[取得] 需求單項目 失敗:', error);
    throw error;
  }
}

// [取得] 群組資訊
export const getGroup = async (group_id?: string) => {
  try {
    let response;
    if (!group_id) {
      response = await callApi.get(`/group/`);
    } else {
      response = await callApi.get(`/group/${group_id}`);
    }
    return response.data;
  } catch (error) {
    console.error('[取得] 群組資訊失敗:', error);
    throw error;
  }
}